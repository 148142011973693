import { defineStore } from "pinia";

export const useSnackBarStore = defineStore("SnackBarStore", () => {
  const show = ref(false);
  const message = ref("");
  const color = ref("");
  const timeOutMilliSecond = ref(3000);
  const timerId = ref<NodeJS.Timeout | undefined>(undefined);

  const set = (
    snackBarMessage: string,
    snackBarColor = "main-02",
    snackBarTimeOutMilliSecond = 3000,
  ) => {
    show.value = true;
    message.value = snackBarMessage;
    color.value = snackBarColor;
    timeOutMilliSecond.value = snackBarTimeOutMilliSecond;
    setSnackBarTimer();
  };

  const setError = (snackBarMessage: string, snackBarTimeOutMilliSecond = 5000) => {
    set(snackBarMessage, "error", snackBarTimeOutMilliSecond);
  };

  // 自動非表示のためのタイマー設定
  // ページが切り替わった時にSnackBarコンポーネントが消えてしまうため、showがfalseにならない。
  // 再度そのページに遷移した際にshowがtrueのままなのでSnackBarが表示されてしまう。
  // その対策として自前でtimeout処理を実装する
  const setSnackBarTimer = () => {
    clearSnackBarTimer();
    timerId.value = setTimeout(() => {
      show.value = false;
    }, timeOutMilliSecond.value);
  };

  // 削除
  const clearSnackBarTimer = () => {
    if (timerId.value) {
      clearTimeout(timerId.value);
      timerId.value = undefined;
    }
  };

  return {
    show,
    message,
    color,
    timeOutMilliSecond,
    set,
    setError,
  };
});
