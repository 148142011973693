import validate from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import log_45global from "/home/runner/work/fincs-webfront/fincs-webfront/src/middleware/log.global.ts";
import sentry_45global from "/home/runner/work/fincs-webfront/fincs-webfront/src/middleware/sentry.global.ts";
import manifest_45route_45rule from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  log_45global,
  sentry_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/middleware/auth.ts"),
  "auth-admin": () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/middleware/authAdmin.ts")
}