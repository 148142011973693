import type { IOwnerRepository } from "~/interfaces/IOwnerRepository";
import { Owners } from "~/entities/Owners";
import { type CONTENT_SORT_TYPE } from "~/consts/contentsConfig";
import { OwnerInfo } from "~/entities/OwnerInfo";
import { PlanInfo } from "~/entities/PlanInfo";

export class OwnerRepository implements IOwnerRepository {
  /**
   * オーナー情報一覧を取得する
   */
  async fetch(page: number, pageSize: number, sort?: CONTENT_SORT_TYPE): Promise<Owners> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: pageSize.toString(),
    };
    if (sort !== undefined) {
      paramsObj.sort = sort.toString();
    }
    const searchParams = new URLSearchParams(paramsObj);

    const owners = await GetRequest<Owners>(`/owner?` + searchParams.toString());
    return owners;
  }

  /**
   * オーナーを取得する
   */
  async fetchOne(
    ownerId: string,
  ): Promise<{ statusCode: number; owner: OwnerInfo; plans: PlanInfo[] }> {
    const response = await GetRequest<{ statusCode: number; owner: OwnerInfo; plans: PlanInfo[] }>(
      `/owner/${ownerId}`,
    );
    return response;
  }
}
