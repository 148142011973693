import { PlanInfo } from "../entities/PlanInfo";

export function getOwnerIds(planInfo: PlanInfo): string[] {
  return [planInfo.planOwner!.userId, ...planInfo.subOwnerIds];
}

export interface IPlanBillingType {
  name: string; // 表示名
  type: "contract_period" | "only_once"; // 契約タイプ (contract_period: サブスクリプション, only_once: 一度きり)
  contractPeriod?: number; // 契約期間 (contract_periodの場合のみ)
  availableDays?: number; // 有効日数 (only_onceの場合のみ)
}

export const PLAN_PRICE_BILLING_TYPE_LIST: IPlanBillingType[] = [
  // サブスク 1ヶ月、2ヶ月、3ヶ月、4ヶ月、5ヶ月、6ヶ月
  { name: "[サブスク]1ヶ月", type: "contract_period", contractPeriod: 1 },
  { name: "[サブスク]2ヶ月", type: "contract_period", contractPeriod: 2 },
  { name: "[サブスク]3ヶ月", type: "contract_period", contractPeriod: 3 },
  { name: "[サブスク]4ヶ月", type: "contract_period", contractPeriod: 4 },
  { name: "[サブスク]5ヶ月", type: "contract_period", contractPeriod: 5 },
  { name: "[サブスク]6ヶ月", type: "contract_period", contractPeriod: 6 },
  // 一括 7日、14日、1ヶ月、2ヶ月、3ヶ月、4ヶ月、5ヶ月、6ヶ月、無期限
  { name: "[一括]7日間", type: "only_once", availableDays: 7 },
  { name: "[一括]14日間", type: "only_once", availableDays: 14 },
  { name: "[一括]1ヶ月", type: "only_once", availableDays: 30 },
  { name: "[一括]2ヶ月", type: "only_once", availableDays: 60 },
  { name: "[一括]3ヶ月", type: "only_once", availableDays: 90 },
  { name: "[一括]4ヶ月", type: "only_once", availableDays: 120 },
  { name: "[一括]5ヶ月", type: "only_once", availableDays: 150 },
  { name: "[一括]6ヶ月", type: "only_once", availableDays: 180 },
  { name: "[一括]無期限", type: "only_once", availableDays: 0 },
];

export function getPlanBillingType(
  type: string,
  contractPeriod: number,
  availableDays: number,
): IPlanBillingType | undefined {
  return PLAN_PRICE_BILLING_TYPE_LIST.find(billingType => {
    if (billingType.type !== type) {
      return false;
    }
    if (type === "contract_period") {
      return billingType.contractPeriod === contractPeriod;
    }
    return billingType.availableDays === availableDays;
  });
}

export const PLAN_PRICE_TRYAL_DAYS_LIST = [0, 7, 14, 30];
