import type { IAdminToolRepository } from "~/interfaces/IAdminToolRepository";
import { type APIRequestMethod } from "~/utils/ApiClient";

export class AdminToolRepository implements IAdminToolRepository {
  /**
   * リクエストを送信する
   * @param method リクエストメソッド
   * @param uri リクエストURI
   * @param requestBody リクエストボディ
   * @returns リクエストの結果
   */
  async request(method: APIRequestMethod, uri: string, requestBody: string): Promise<string> {
    switch (method) {
      case "GET":
        return await GetRequest<string>(uri, true, true);
      case "POST":
        return await PostRequest<string>(uri, requestBody, true, false, true);
      case "PUT":
        return await PutRequest<string>(uri, requestBody, true, true);
      case "PATCH":
        return await PatchRequest<string>(uri, requestBody, true, true);
      case "DELETE":
        return await DeleteRequest<string>(uri, true, true);
      default:
        throw new Error("AdminToolRepository.request method Error");
    }
  }
}
