import { defineStore } from "pinia";
import { LineLinkInfo } from "~/entities/LineLinkInfo";
import { ILineLinkRepositoryFactoryKey, injectRepository } from "~/Repositories/provide";
import type { ILineLinkRepository } from "~/interfaces/ILineLinkRepository";

export const useLineLinkStore = defineStore("LineLinkStore", () => {
  const repository: ILineLinkRepository = injectRepository(ILineLinkRepositoryFactoryKey);

  // リダイレクト処理用にベースURLを取得
  const getBaseUrl = () => window.location.origin;

  const lineLinkInfo = ref<LineLinkInfo>();
  const loading = ref(false);

  const fetch = async () => {
    loading.value = true;
    try {
      lineLinkInfo.value = await repository.fetch();
    } finally {
      loading.value = false;
    }
  };

  const link = async (code: string, state: string) => {
    loading.value = true;
    try {
      await repository.link(code, state);
    } finally {
      loading.value = false;
    }
  };

  const linkRevoke = async () => {
    loading.value = true;
    try {
      await repository.linkRevoke();
    } finally {
      loading.value = false;
    }
  };

  const subscribePlan = async (planId: string) => {
    loading.value = true;
    try {
      await repository.subscribePlan(planId);
    } finally {
      loading.value = false;
    }
  };

  const unsubscribePlan = async (planId: string) => {
    loading.value = true;
    try {
      await repository.unsubscribePlan(planId);
    } finally {
      loading.value = false;
    }
  };

  const openLink = (planId: string) => {
    const link =
      "https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=" +
      lineLinkInfo.value?.clientId +
      "&redirect_uri=" +
      encodeURI(getBaseUrl() + "/line_redirect") +
      "&scope=notify&state=" +
      lineLinkInfo.value?.state +
      "planId--" +
      planId;

    setTimeout(() => {
      window.open(link);
    });
  };

  return {
    fetch,
    link,
    linkRevoke,
    subscribePlan,
    unsubscribePlan,
    openLink,
    lineLinkInfo,
    loading,
  };
});
