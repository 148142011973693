import type { ITagRepository } from "~/interfaces/ITagRepository";
import { TagsResponse } from "~/entities/TagsResponse";

export class TagRepository implements ITagRepository {
  /**
   * tag一覧を取得する
   */
  async fetch(): Promise<TagsResponse> {
    const tags = await GetRequest<TagsResponse>("/tags");
    return tags;
  }
}
