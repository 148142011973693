import { defineStore } from "pinia";
import { useContentsStore } from "./ContentsStore";
import { MovieInfo } from "~/entities/MovieInfo";
import { Movies } from "~/entities/Movies";

export const useMovieStore = defineStore("MovieStore", () => {
  const contentStore = useContentsStore();

  const movie = ref<MovieInfo>();
  const movies = ref<Movies>();
  const planMovies = ref<Movies>(); // コンテンツ閲覧画面下部に表示するデータ
  const loading = ref(false);

  const fetchOne = async (contentsId: string) => {
    loading.value = true;
    movie.value = undefined;
    try {
      movie.value = await contentStore.getDetail<MovieInfo>(contentsId);
    } finally {
      loading.value = false;
    }
  };

  // optionsにis_free, search_wordが入ります。
  const fetch = async (
    page: number,
    pageSize: number,
    planId: string | undefined = undefined,
    options: Record<string, any> = {},
  ) => {
    loading.value = true;
    movies.value = undefined;
    try {
      movies.value = await contentStore.getList<Movies>(page, pageSize, {
        contents_types: ["movie", "archive_movie"],
        plan_id: planId,
        ...options,
      });
    } finally {
      loading.value = false;
    }
  };
  // コンテンツ閲覧画面下部に表示するデータを取得する
  const fetchPlanMovies = async (
    page: number,
    pageSize: number,
    planId: string | undefined = undefined,
    exclusionContentsId: string | undefined = undefined,
  ) => {
    loading.value = true;
    planMovies.value = undefined;
    try {
      planMovies.value = await contentStore.getList<Movies>(page, pageSize, {
        contents_types: ["movie", "archive_movie"],
        plan_id: planId,
        exclusion_contents_id: exclusionContentsId,
      });
    } finally {
      loading.value = false;
    }
  };

  return {
    movie,
    movies,
    planMovies,
    loading,
    fetchOne,
    fetch,
    fetchPlanMovies,
  };
});
