// FireStore用LINEリンク状態

// ステータスコード
export enum LineLinkStatusType {
  Start = "start",
  Success = "success",
  Failed = "failed",
  Canceled = "canceled",
}

// ライブステータス
export class LineLinkStatus {
  constructor(
    public userId: string,
    public planId: string,
    public status: string | LineLinkStatusType,
    public error: string,
    public errorDescription: string,
    public createdAt: Date | null,
  ) {}

  toString() {
    return `${this.userId}, ${this.planId}, ${this.status}, ${this.error}, ${this.errorDescription}, ${this.createdAt}`;
  }
}

// Firestore data converter
export const LineLinkStatusConverter = {
  toFirestore: (item: any) => {
    return {
      userId: item.userId,
      planId: item.planId,
      status: item.status,
      error: item.error,
      errorDescription: item.errorDescription,
      createdAt: item.createdAt ?? new Date(),
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new LineLinkStatus(
      data.userId,
      data.planId,
      data.status,
      data.error,
      data.errorDescription,
      data.createdAt?.toDate(),
    );
  },
};
