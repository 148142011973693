import type { IPlanRepository } from "~/interfaces/IPlanRepository";
import { Plans } from "~/entities/Plans";
import { PlanResponse } from "~/entities/PlanResponse";
import { PlanInfo } from "~/entities/PlanInfo";
import { PlanReviews } from "~/entities/PlanReviews";
import { type CONTENT_SORT_TYPE } from "~/consts/contentsConfig";
import { ContractMemberResponse } from "~/entities/ContractMemberResponse";
import { PlanReviewApplicableResponse } from "~/entities/PlanReviewApplicableResponse";
import { ContractStatisticResponse } from "~/entities/ContractStatisticResponse";
import { PlanDocumentsResponse } from "~/entities/PlanDocumentsResponse";
import { PlanReviewRequest } from "~/entities/PlanReviewRequest";
import { HttpCommonResponse } from "~/entities/HttpCommonResponse";
import { PlanDocument } from "~/entities/PlanDocument";

export class PlanRepository implements IPlanRepository {
  /**
   * 指定した講座情報を取得する
   */
  async fetch(
    page: number,
    pageSize: number,
    category?: string | undefined,
    isAdvice?: boolean | undefined,
    ownerUserId?: string | undefined,
    isContracted?: boolean | undefined,
    isSubscription?: boolean | undefined,
    sort?: CONTENT_SORT_TYPE,
  ): Promise<Plans> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: pageSize.toString(),
    };
    if (category !== undefined) {
      paramsObj.category = category;
    }
    if (isAdvice !== undefined) {
      paramsObj.is_investment_advice = isAdvice.toString();
    }
    if (ownerUserId !== undefined) {
      paramsObj.owner_user_id = ownerUserId;
    }
    if (isContracted !== undefined) {
      paramsObj.is_contracted = isContracted.toString();
    }
    if (isSubscription !== undefined) {
      paramsObj.is_subscription = isSubscription.toString();
    }
    if (sort !== undefined) {
      paramsObj.sort = sort.toString();
    }
    const searchParams = new URLSearchParams(paramsObj);

    const plans = await GetRequest<Plans>(`/plan?` + searchParams.toString());
    return plans;
  }

  /**
   * 指定の講座情報を取得する
   */
  async fetchOne(planId: string): Promise<PlanInfo> {
    const plan = await GetRequest<PlanResponse>(`/plan/${planId}`);
    return plan?.plan;
  }

  /**
   * 指定の講座の交付文書情報を取得する
   */
  async fetchDocuments(planId: string): Promise<PlanDocument[]> {
    const plan = await GetRequest<PlanDocumentsResponse>(`/plan/${planId}/contract_document`);
    return plan?.documents;
  }

  /**
   * 指定したplanのレビュー情報を取得する
   */
  async fetchPlanReviews(
    page: number,
    pageSize: number,
    planId: string,
    userId?: string | undefined,
  ): Promise<PlanReviews> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: pageSize.toString(),
    };
    if (userId !== undefined) {
      paramsObj.user_id = userId;
    }
    const searchParams = new URLSearchParams(paramsObj);

    const planReviews = await GetRequest<PlanReviews>(`/review/${planId}?` + searchParams.toString());
    return planReviews;
  }

  /**
   * 講座契約メンバーを取得する
   * @param page
   * @param pageSize
   * @param planId
   * @param options: sort, sortItem, userId, userName
   */
  async fetchMembers(
    page: number,
    pageSize: number,
    planId: string,
    options?: Record<string, any>,
  ): Promise<ContractMemberResponse> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: pageSize.toString(),
      ...filterEmptyObject(options || {}),
    };
    const searchParams = new URLSearchParams(paramsObj);

    const response = await GetRequest<ContractMemberResponse>(
      `/contract_owner/member_list/${planId}?` + searchParams.toString(),
    );
    return response;
  }

  /**
   * 講座統計を取得する
   * @param planId
   * @param period
   */
  async fetchStatistics(planId: string, planPriceId?: number[]): Promise<ContractStatisticResponse> {
    const paramsObj: { [name: string]: string } = {};
    if (planPriceId !== undefined) {
      for (let i = 0; i < planPriceId.length; i++) {
        if (!planPriceId[i] === false) {
          paramsObj.plan_price_id = String(planPriceId[i]);
        }
      }
    }
    const searchParams = new URLSearchParams(paramsObj);

    const response = await GetRequest<ContractStatisticResponse>(
      `/contract_owner/statistics/${planId}?` + searchParams.toString(),
    );
    return response;
  }

  /**
   * レビュー条件情報取得
   */
  async fetchReviewApplicable(planId: string): Promise<PlanReviewApplicableResponse> {
    const response = await GetRequest<PlanReviewApplicableResponse>(`/review/user/${planId}`);
    return response;
  }

  /**
   * レビュー投稿
   */
  async postReview(planId: string, planReviewRequest: PlanReviewRequest): Promise<void> {
    const res = await PostRequest<HttpCommonResponse>(
      `/review/${planId}`,
      JSON.stringify({
        total_points: planReviewRequest.totalPoint,
        title: planReviewRequest.title,
        content: planReviewRequest.content,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`投稿に失敗しました`);
    }
  }

  /**
   * レビュー投稿
   */
  async putReview(reviewId: string, planReviewRequest: PlanReviewRequest): Promise<void> {
    const res = await PutRequest<HttpCommonResponse>(
      `/review/${reviewId}`,
      JSON.stringify({
        total_points: planReviewRequest.totalPoint,
        title: planReviewRequest.title,
        content: planReviewRequest.content,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`投稿に失敗しました`);
    }
  }

  /**
   * レビュー削除
   */
  async deleteReview(reviewId: string): Promise<void> {
    const res = await DeleteRequest<HttpCommonResponse>(`/review/${reviewId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error(`削除に失敗しました`);
    }
  }

  /**
   * レビュー非公開設定変更
   */
  async updateReviewPrivate(reviewId: string, isPrivate: boolean): Promise<void> {
    const res = await PutRequest<HttpCommonResponse>(
      `/review/${reviewId}/private`,
      JSON.stringify({
        is_private: isPrivate,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`非公開設定の変更に失敗しました`);
    }
  }

  /**
   * 講座詳細更新
   */
  async patchPlan(editPlan: PlanInfo): Promise<void> {
    const res = await PatchRequest<HttpCommonResponse>(
      `/plan/${editPlan.id}`,
      JSON.stringify({
        image_url: editPlan.thumbnailImage,
        introduction: editPlan.introduction,
        sub_introduction: editPlan.subIntroduction,
        is_free_plan: editPlan.isFreePlan,
        is_investment_advice: editPlan.isInvestmentAdvice,
        plan_summary: editPlan.summary,
        plan_title: editPlan.title,
        tags: editPlan.tags.map(tag => Number(tag.id)),
        is_available_review: editPlan.isAvailableReview,
        available_review_day: editPlan.availableReviewPeriod,
        is_hidden_member_count: editPlan.isHiddenMemberCount,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`講座の更新に失敗しました`);
    }
  }

  /**
   * ファイルをアップロードする
   */
  async uploadImageFile(file: File): Promise<{ fileUrl: string }> {
    return await this.uploadFile(`/plan/presigned_url`, file);
  }

  private async uploadFile(apiPath: string, file: File) {
    // 署名付きS3アップロードURLを取得する
    const mimeType = file.type;
    const presignedRes = await GetRequest<{
      statusCode: number;
      presignedUrl: string;
      fileUrl: string;
    }>(`${apiPath}?mime_type=${mimeType}`);
    if (!presignedRes || presignedRes.statusCode !== 200) {
      throw new Error("Failed get presigned_url");
    }

    // S3 にアップロード
    const s3Res = await PutS3Request(presignedRes.presignedUrl, file);
    if (!s3Res || s3Res.statusCode !== 200) {
      throw new Error("Failed to put s3");
    }

    const fileUrl = presignedRes.fileUrl;
    return { fileUrl };
  }
}
