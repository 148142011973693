import axios from "axios";
import { collection, doc, documentId, query, setDoc, where } from "firebase/firestore";
import type { IContentsRepository } from "~/interfaces/IContentsRepository";
import { UserContentsInfo, UserContentsInfoConverter } from "~/entities/UserContentsInfo";
import { ContentsResponse } from "~/entities/ContentsResponse";

export class ContentsRepository implements IContentsRepository {
  /**
   * コンテンツの公開非公開情報を変更する
   */
  async putPublish(contentsId: string, publishStatus: string): Promise<void> {
    const res = await PutRequest<{ statusCode: number }>(
      `/contents_manage/publish`,
      JSON.stringify({
        contents_list: [
          {
            contents_id: contentsId,
            publish_status: publishStatus,
          },
        ],
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to put publish");
    }
  }

  /**
   * コンテンツ閲覧フラグを立てる
   */
  async postBrowse(contentsId: string, userId: string): Promise<void> {
    // firestore の既読フラグを更新
    const userInfo = await this.getUserContentsInfo(userId, contentsId);
    if (!userInfo.read) {
      userInfo.read = true;
      await this.putUserContentsInfo(userId, contentsId, userInfo);
    }
    // TODO 旧コンテンツ閲覧ロギング用API
    // 移行期間後に削除する
    // https://arkad-co.slack.com/archives/C06P8P9L95G/p1719450662151619?thread_ts=1718789982.308579&cid=C06P8P9L95G
    const res = await PostRequest<{ statusCode: number }>(`/contents/browse/${contentsId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to post browse");
    }

    // コンテンツ閲覧ロギング用API
    const apiBaseUrl = useRuntimeConfig().public.contentsBrowseLoggingApiBaseUrl as string;
    if (apiBaseUrl.length > 0) {
      await axios.post(`${apiBaseUrl}/contents`, {
        user_id: userId,
        contents_id: Number(contentsId),
      });
    }
  }

  /**
   * コンテンツ視聴完了
   */
  async postComplete(contentsId: string): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(`/contents/complete/${contentsId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to post complete");
    }
  }

  /**
   * いいねする
   */
  async postGood(contentsId: string): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(`/contents/good/${contentsId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to post good");
    }
  }

  /**
   * いいね解除
   */
  async deleteGood(contentsId: string): Promise<void> {
    const res = await DeleteRequest<{ statusCode: number }>(`/contents/good/${contentsId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to delete good");
    }
  }

  /**
   * ユーザーごとのコンテンツリストを取得
   */
  async getUserContentsList(userId: string, contentsIds: string[]): Promise<UserContentsInfo[]> {
    if (contentsIds.length > 30) {
      throw new Error(
        "getUserContentsList: contentsIds must have 30 or less items due to limit of where in statement of firestore query",
      );
    }
    const querySnapshot = await getFirestoreDocs(
      query(this.userContentsInfoCollection(userId), where(documentId(), "in", contentsIds)),
    );
    if (querySnapshot.empty) {
      return []; // Or however you wish to handle an empty result
    }
    return querySnapshot.docs.map(
      doc =>
        ({
          id: doc.id,
          ...doc.data(),
        }) as UserContentsInfo,
    );
  }

  /**
   * ユーザーごとのコンテンツ情報を取得
   */
  async getUserContentsInfo(userId: string, contentsId: string): Promise<UserContentsInfo> {
    const snap = await getFirestoreDoc(this.userContentsInfoDoc(userId, contentsId));
    if (!snap.exists()) {
      return UserContentsInfo.empty();
    }
    return snap.data();
  }

  /**
   * ユーザーごとのコンテンツ情報を更新
   */
  async putUserContentsInfo(userId: string, contentsId: string, info: UserContentsInfo): Promise<void> {
    await setDoc(this.userContentsInfoDoc(userId, contentsId), info);
  }

  userContentsInfoCollection(userId: string) {
    const { $firestore } = useNuxtApp();
    return collection($firestore, "users", userId, "contents").withConverter(UserContentsInfoConverter);
  }

  userContentsInfoDoc(userId: string, contentsId: string) {
    const { $firestore } = useNuxtApp();
    return doc($firestore, "users", userId, "contents", contentsId).withConverter(UserContentsInfoConverter);
  }

  /**
   * コンテンツ情報を取得
   * @param page ページ番号
   * @param pageSize 1ページあたりの件数
   * @param options contents_types: コンテンツタイプリスト([movie/live_movie/archive_movie/archive_movie_progress/document/schedule])
   * @param options exclusion_contents_id: 除外するコンテンツID
   * @param options is_contracted: 契約済みのコンテンツのみ取得するか
   * @param options is_free: 無料のコンテンツのみ取得するか
   * @param options owner_user_id: コンテンツの所有者のユーザーID
   * @param options plan_id: プランID
   * @param options search_word: 検索キーワード
   * @param options sort: ソート順[desc/asc]（nullの場合desc)
   * @param options tag: タグ
   * @param options user_role: ユーザーのロール
   */
  async getList<T>(page: number, pageSize: number, options?: Record<string, any>): Promise<T> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: pageSize.toString(),
      ...filterEmptyObject(options || {}),
    };
    const searchParams = new URLSearchParams(paramsObj);
    return await GetRequest<T>(`/contents/list?` + searchParams.toString());
  }

  async getDetail<T>(contentsId: string): Promise<T> {
    const response = await GetRequest<ContentsResponse<T>>(`/contents/${contentsId}`);
    return response?.contents;
  }
}
