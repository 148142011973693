import { defineStore } from "pinia";
import { PlanInfo } from "~/entities/PlanInfo";
import { PlanReviews } from "~/entities/PlanReviews";
import { PlanReviewInfo } from "~/entities/PlanReviewInfo";
import { Plans } from "~/entities/Plans";
import { type IPlanRepository } from "~/interfaces/IPlanRepository";
import { PlanRepository } from "~/Repositories/PlanRepository";
import { useLoginStore } from "~/stores/LoginStore";
import { usePlanEditStore } from "~/stores/PlanEditStore";
import { ContractMemberResponse } from "~/entities/ContractMemberResponse";
import { ContractStatisticResponse } from "~/entities/ContractStatisticResponse";
import { PlanReviewApplicableResponse } from "~/entities/PlanReviewApplicableResponse";
import { PlanReviewRequest } from "~/entities/PlanReviewRequest";
import { type CONTENT_SORT_TYPE } from "~/consts/contentsConfig";
import { PlanDocument } from "~/entities/PlanDocument";
import type { ContractMember } from "~/entities/ContractMember";

export const usePlanStore = defineStore("PlanStore", () => {
  const plan = ref<PlanInfo>();
  const planReviews = ref<PlanReviews>();
  const myReview = ref<PlanReviewInfo>();
  const planSummaryReviews = ref<PlanReviews>();
  const planDocuments = ref<PlanDocument[]>([]);

  const plans = ref<Plans>();
  const ownerPlans = ref<Plans>(); // オーナー指定で取得した講座
  const myOwnerPlans = ref<Plans>(); // 自分の講座
  const contractedPlans = ref<Plans>(); // 契約中の講座
  const planReviewApplicable = ref<PlanReviewApplicableResponse>();
  const contractMemberResponse = ref<ContractMemberResponse>();
  const contractStatisticResponse = ref<ContractStatisticResponse>();

  const loading = ref(false);
  // リスト系のloading
  const loadingList = ref(false);
  // postReviewは別物なのでloadingフラグを分ける
  const loadingPostReview = ref(false);
  // メンバーは別にする
  const loadingMembers = ref(false);
  // 統計のloading
  const loadingStatistics = ref(false);

  const loginStore = useLoginStore();
  const planEditStore = usePlanEditStore();

  const repository: IPlanRepository = new PlanRepository();

  // store内のplanが指定のplanIdと別だった場合はplanをリセットする
  const resetPlan = (planId: string | undefined) => {
    if (plan.value?.id !== planId) {
      plan.value = undefined;
      planEditStore.reset();
    }
  };

  const fetch = async (
    page: number,
    pageSize: number,
    category?: string | undefined,
    isAdvice?: boolean | undefined,
    ownerUserId?: string | undefined,
    isContracted?: boolean | undefined,
    isSubscription?: boolean | undefined,
    sort?: CONTENT_SORT_TYPE,
  ) => {
    loadingList.value = true;
    try {
      plans.value = await repository.fetch(
        page,
        pageSize,
        category,
        isAdvice,
        ownerUserId,
        isContracted,
        isSubscription,
        sort,
      );
    } finally {
      loadingList.value = false;
    }
  };

  /**
   * オーナー指定で取得し、ownerPlansに値を入れる
   * @param page
   * @param pageSize
   * @param category
   * @param isAdvice
   * @param ownerUserId
   * @param isContracted
   * @param isSubscription
   */
  const fetchOwnerPlan = async (
    page: number,
    pageSize: number,
    category: string | undefined,
    isAdvice: boolean | undefined,
    ownerUserId: string | undefined,
    isContracted: boolean | undefined,
    isSubscription: boolean | undefined,
  ) => {
    loading.value = true;
    try {
      ownerPlans.value = undefined;
      ownerPlans.value = await repository.fetch(
        page,
        pageSize,
        category,
        isAdvice,
        ownerUserId,
        isContracted,
        isSubscription,
      );
    } finally {
      loading.value = false;
    }
  };
  const fetchMyOwnerPlan = async () => {
    if (loginStore.isOwner()) {
      loadingList.value = true;
      try {
        myOwnerPlans.value = await repository.fetch(
          1,
          1000,
          undefined,
          undefined,
          loginStore.loginInfo.userId,
          undefined,
          undefined,
          undefined,
        );
      } finally {
        loadingList.value = false;
      }
    }
  };

  const fetchContractedPlan = async () => {
    loadingList.value = true;
    try {
      contractedPlans.value = await repository.fetch(
        1,
        1000,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        undefined,
      );
    } finally {
      loadingList.value = false;
    }
  };

  const fetchOne = async (planId: string) => {
    loading.value = true;
    try {
      plan.value = await repository.fetchOne(planId);
    } finally {
      loading.value = false;
    }
  };

  const fetchPlanReviews = async (
    page: number,
    pageSize: number,
    planId: string | undefined,
    userId?: string | undefined,
  ) => {
    loading.value = true;
    try {
      planReviews.value = await repository.fetchPlanReviews(page, pageSize, planId, userId);
    } finally {
      loading.value = false;
    }
  };

  const fetchMyReview = async (planId: string | undefined, userId: string | undefined) => {
    loading.value = true;
    try {
      myReview.value = undefined;
      const response = await repository.fetchPlanReviews(1, 1, planId, userId);
      if (response.reviews?.length) {
        myReview.value = response.reviews[0];
      }
    } finally {
      loading.value = false;
    }
  };

  // 講座サマリー向けのデータ取得
  const fetchPlanSummaryReviews = async (page: number, pageSize: number, planId: string | undefined) => {
    loading.value = true;
    try {
      planSummaryReviews.value = await repository.fetchPlanReviews(page, pageSize, planId);
    } finally {
      loading.value = false;
    }
  };

  const fetchPlanDocuments = async (planId: string) => {
    loading.value = true;
    try {
      planDocuments.value = await repository.fetchDocuments(planId);
    } finally {
      loading.value = false;
    }
  };

  const fetchMembers = async (page: number, pageSize: number, planId: string, options?: Record<string, any>) => {
    loadingMembers.value = true;
    try {
      contractMemberResponse.value = await repository.fetchMembers(page, pageSize, planId, options);
    } finally {
      loadingMembers.value = false;
    }
  };

  const fetchMember = async (planId: string, userId: string): Promise<ContractMember> => {
    const res = await repository.fetchMembers(1, 1, planId, { user_id: userId });
    return res.contractMembers[0];
  };

  const fetchStatistics = async (planId: string, planPriceId?: number[]) => {
    if (!contractStatisticResponse.value) {
      loadingStatistics.value = true;
    }
    try {
      contractStatisticResponse.value = await repository.fetchStatistics(planId, planPriceId);
    } finally {
      loadingStatistics.value = false;
    }
  };

  const fetchReviewApplicable = async (planId: string) => {
    loading.value = true;
    planReviewApplicable.value = undefined;
    try {
      planReviewApplicable.value = await repository.fetchReviewApplicable(planId);
    } finally {
      loading.value = false;
    }
  };

  const postReview = async (planId: string, planReviewRequest: PlanReviewRequest) => {
    loadingPostReview.value = true;
    try {
      await repository.postReview(planId, planReviewRequest);
    } finally {
      loadingPostReview.value = false;
    }
  };

  const putReview = async (reviewId: string, planReviewRequest: PlanReviewRequest) => {
    loadingPostReview.value = true;
    try {
      await repository.putReview(reviewId, planReviewRequest);
    } finally {
      loadingPostReview.value = false;
    }
  };

  const deleteReview = async (reviewId: string) => {
    loadingPostReview.value = true;
    try {
      await repository.deleteReview(reviewId);
    } finally {
      loadingPostReview.value = false;
    }
  };

  const updateReviewPrivate = async (reviewId: string, isPrivate: boolean) => {
    loadingPostReview.value = true;
    try {
      await repository.updateReviewPrivate(reviewId, isPrivate);
    } finally {
      loadingPostReview.value = false;
    }
  };

  // レビュー可能かを返す
  // 汎用的に使えるようにplan、planReviewApplicableを引数にしている
  const isReviewable = (
    plan: PlanInfo | undefined,
    planReviewApplicable: PlanReviewApplicableResponse | undefined,
  ): boolean => {
    if (!plan || !planReviewApplicable) {
      // データ取得前は非表示
      return false;
    }

    if (!plan.isContract) {
      // 入会していない場合は非表示
      return false;
    }

    if (!plan.isAvailableReview) {
      // 非表示の場合は非表示
      return false;
    }

    // レビュー可能か
    if (planReviewApplicable.isReviewAvailable === false) {
      return false;
    }
    return true;
  };

  return {
    plan,
    planReviews,
    myReview,
    planSummaryReviews,
    planDocuments,
    plans,
    ownerPlans,
    myOwnerPlans,
    contractedPlans,
    contractMemberResponse,
    contractStatisticResponse,
    planReviewApplicable,
    loading,
    loadingList,
    loadingPostReview,
    loadingMembers,
    loadingStatistics,
    resetPlan,
    fetchOne,
    fetch,
    fetchOwnerPlan,
    fetchMyOwnerPlan,
    fetchContractedPlan,
    fetchPlanReviews,
    fetchMyReview,
    fetchPlanSummaryReviews,
    fetchPlanDocuments,
    fetchMembers,
    fetchMember,
    fetchStatistics,
    fetchReviewApplicable,
    postReview,
    putReview,
    deleteReview,
    updateReviewPrivate,
    isReviewable,
  };
});
