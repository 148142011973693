import { defineStore } from "pinia";

export const useReceiptStore = defineStore(
  "ReceiptStore",
  () => {
    const email = ref<string>("");
    const name = ref<string>("");

    // 初期化
    const reset = () => {
      email.value = "";
      name.value = "";
    };

    const set = (emailValue: string, nameValue: string) => {
      email.value = emailValue;
      name.value = nameValue;
    };

    return {
      email,
      name,
      reset,
      set,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
