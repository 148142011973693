// トーク既読ステータス
export class TalkLastRead {
  constructor(
    public talkRoomId: string,
    public lastReadTalkId: number,
  ) {}
}

// Firestore data converter
export const TalkLastReadConverter = {
  toFirestore: (item: any) => {
    return {
      lastReadTalkId: item.lastReadTalkId,
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new TalkLastRead(snapshot.id, data.lastReadTalkId);
  },
};
