import { default as logind0Qqeooh07Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/login.vue?macro=true";
import { default as regist_mfapGooDlm156Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/regist_mfa.vue?macro=true";
import { default as send_emailgtAgXpK3mtMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/send_email.vue?macro=true";
import { default as signupJqDHuga3v5Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/signup.vue?macro=true";
import { default as tooluhhCvghyCgMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/tool.vue?macro=true";
import { default as verify_mfa3O8u5mo7sfMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/verify_mfa.vue?macro=true";
import { default as indexAMVR1MppuyMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/document/[id]/index.vue?macro=true";
import { default as indexXMghH72TH4Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/index.vue?macro=true";
import { default as line_link_finish4uSIMAyjLaMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_finish.vue?macro=true";
import { default as line_link_revoke_finishMXLN9X1FUYMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_revoke_finish.vue?macro=true";
import { default as line_link_revoke4HcZ7TIgeHMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_revoke.vue?macro=true";
import { default as line_redirectFftxViHlYSMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_redirect.vue?macro=true";
import { default as hostAKcx5rHD6eMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/host.vue?macro=true";
import { default as indexZ6lrMP6ZLvMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/index.vue?macro=true";
import { default as resultdkGbdgWigmMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/result.vue?macro=true";
import { default as _91id_937mnQNjVQ0kMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/livearchive/[id].vue?macro=true";
import { default as _91id_93TtRczRcjfLMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/liveschedule/[id].vue?macro=true";
import { default as loginn9mrUV14R2Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/login.vue?macro=true";
import { default as embedULW98ihf3yMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/movie/[id]/embed.vue?macro=true";
import { default as indexzTwvbf4FUlMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/movie/[id]/index.vue?macro=true";
import { default as emailfOq3CDFhgrMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/email.vue?macro=true";
import { default as indexKJ25IUbbwMMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/index.vue?macro=true";
import { default as personalinfo6TTdE9Xr8qMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/personalinfo.vue?macro=true";
import { default as profileNl6CDcgUJTMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/profile.vue?macro=true";
import { default as _91id_937IwTTQPD7TMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/owner/[id].vue?macro=true";
import { default as createEnGwZA49ZGMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/create.vue?macro=true";
import { default as history7i5eXbTFoTMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/history.vue?macro=true";
import { default as indextW5Be7sUH9Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/index.vue?macro=true";
import { default as addpaymentDXArqP5HBnMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/addpayment.vue?macro=true";
import { default as completesW3S5GIfwgMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/complete.vue?macro=true";
import { default as confirmZaJN544jSMMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/confirm.vue?macro=true";
import { default as noticeocPvnygYG8Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/notice.vue?macro=true";
import { default as reasonPgWqfryCjvMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/reason.vue?macro=true";
import { default as starthlhSNoMOrIMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/start.vue?macro=true";
import { default as indexjvPGDBbHRdMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/index.vue?macro=true";
import { default as indexh5wtssseCzMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/payment/index.vue?macro=true";
import { default as newZtC8agqjbyMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/payment/new.vue?macro=true";
import { default as completeGwgb7Zmwb2Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/complete.vue?macro=true";
import { default as editC4Feea3JceMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/document/[contentId]/edit.vue?macro=true";
import { default as createx4X1lzIP0gMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/document/create.vue?macro=true";
import { default as edit2CVxn6OxfUMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/live/[contentId]/edit.vue?macro=true";
import { default as createNM6LflVeKKMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/live/create.vue?macro=true";
import { default as editJlKNPkn16VMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/livearchive/[contentId]/edit.vue?macro=true";
import { default as editwtSXXROGNWMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/movie/[contentId]/edit.vue?macro=true";
import { default as creates2qPeuWwYHMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/movie/create.vue?macro=true";
import { default as historyma1XlulGeBMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/[campaignId]/history.vue?macro=true";
import { default as createKlJ8S3lejMMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/create.vue?macro=true";
import { default as edit5SIfklITIjMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/edit.vue?macro=true";
import { default as indexA6UVoPlMZzMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/document/[contentId]/index.vue?macro=true";
import { default as indexWuTtb94UTfMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/edit/index.vue?macro=true";
import { default as indexv97DdaNqmIMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/index.vue?macro=true";
import { default as addpaymentsq42VhWgW6Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/addpayment.vue?macro=true";
import { default as agreementGFEsJTPCAHMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/agreement.vue?macro=true";
import { default as authemailKy7LhKo6XiMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/authemail.vue?macro=true";
import { default as complete0yCd5pXbWvMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/complete.vue?macro=true";
import { default as confirmOHzc6ixkgiMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/confirm.vue?macro=true";
import { default as documents9wlJZrMmxDMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/documents.vue?macro=true";
import { default as payment_45bankDTelTLt7h2Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-bank.vue?macro=true";
import { default as payment_45cardzpYvXwfe8UMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-card.vue?macro=true";
import { default as payment_45methodWLUKCmDqo5Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-method.vue?macro=true";
import { default as personalinfo07Z9455hNFMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/personalinfo.vue?macro=true";
import { default as createxCnAO2BpJXMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/library/create.vue?macro=true";
import { default as edit4Muye1m9ZjMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/library/edit.vue?macro=true";
import { default as hosts4BLQaSyvrMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/host.vue?macro=true";
import { default as indexyqggVynpr9Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/index.vue?macro=true";
import { default as result1CrO4LwJ8pMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/result.vue?macro=true";
import { default as indexhE8evGGaLRMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/livearchive/[contentId]/index.vue?macro=true";
import { default as indexTj5ezZ4UOpMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/liveschedule/[contentId]/index.vue?macro=true";
import { default as embedn4LBFd02fzMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/movie/[contentId]/embed.vue?macro=true";
import { default as indexFBLyS66WD7Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/movie/[contentId]/index.vue?macro=true";
import { default as paymentpPMmpJbGdkMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/payment.vue?macro=true";
import { default as editkJCxeglNl1Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/[priceId]/edit.vue?macro=true";
import { default as history19JuObZM8tMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/[priceId]/history.vue?macro=true";
import { default as createOEcRhcoskGMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/create.vue?macro=true";
import { default as completecmR7yrUJ5PMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/review/complete.vue?macro=true";
import { default as indexCTMb6bIQ4TMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/review/index.vue?macro=true";
import { default as index8GQbyMPh8PMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/reviews/index.vue?macro=true";
import { default as editSscvfP2vQ3Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/[talkRoomId]/edit.vue?macro=true";
import { default as editdm0vlkjdo2KyMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/[talkRoomId]/editdm.vue?macro=true";
import { default as create93acIT57TkMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/create.vue?macro=true";
import { default as joiningLi8xx6I92EMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plans/joining.vue?macro=true";
export default [
  {
    name: "admin-login",
    path: "/admin/login",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: "admin-regist_mfa",
    path: "/admin/regist_mfa",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/regist_mfa.vue").then(m => m.default || m)
  },
  {
    name: "admin-send_email",
    path: "/admin/send_email",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/send_email.vue").then(m => m.default || m)
  },
  {
    name: "admin-signup",
    path: "/admin/signup",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/signup.vue").then(m => m.default || m)
  },
  {
    name: "admin-tool",
    path: "/admin/tool",
    meta: tooluhhCvghyCgMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/tool.vue").then(m => m.default || m)
  },
  {
    name: "admin-verify_mfa",
    path: "/admin/verify_mfa",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/verify_mfa.vue").then(m => m.default || m)
  },
  {
    name: "document-id",
    path: "/document/:id()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/document/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "line_link_finish",
    path: "/line_link_finish",
    meta: line_link_finish4uSIMAyjLaMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_finish.vue").then(m => m.default || m)
  },
  {
    name: "line_link_revoke_finish",
    path: "/line_link_revoke_finish",
    meta: line_link_revoke_finishMXLN9X1FUYMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_revoke_finish.vue").then(m => m.default || m)
  },
  {
    name: "line_link_revoke",
    path: "/line_link_revoke",
    meta: line_link_revoke4HcZ7TIgeHMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_revoke.vue").then(m => m.default || m)
  },
  {
    name: "line_redirect",
    path: "/line_redirect",
    meta: line_redirectFftxViHlYSMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_redirect.vue").then(m => m.default || m)
  },
  {
    name: "live-id-host",
    path: "/live/:id()/host",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/host.vue").then(m => m.default || m)
  },
  {
    name: "live-id",
    path: "/live/:id()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "live-id-result",
    path: "/live/:id()/result",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/result.vue").then(m => m.default || m)
  },
  {
    name: "livearchive-id",
    path: "/livearchive/:id()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/livearchive/[id].vue").then(m => m.default || m)
  },
  {
    name: "liveschedule-id",
    path: "/liveschedule/:id()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/liveschedule/[id].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginn9mrUV14R2Meta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "movie-id-embed",
    path: "/movie/:id()/embed",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/movie/[id]/embed.vue").then(m => m.default || m)
  },
  {
    name: "movie-id",
    path: "/movie/:id()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/movie/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-email",
    path: "/mypage/email",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/email.vue").then(m => m.default || m)
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexKJ25IUbbwMMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-personalinfo",
    path: "/mypage/personalinfo",
    meta: personalinfo6TTdE9Xr8qMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/personalinfo.vue").then(m => m.default || m)
  },
  {
    name: "mypage-profile",
    path: "/mypage/profile",
    meta: profileNl6CDcgUJTMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: "owner-id",
    path: "/owner/:id()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/owner/[id].vue").then(m => m.default || m)
  },
  {
    name: "payment-create",
    path: "/payment/create",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/create.vue").then(m => m.default || m)
  },
  {
    name: "payment-history",
    path: "/payment/history",
    meta: history7i5eXbTFoTMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/history.vue").then(m => m.default || m)
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-addpayment",
    path: "/plan/:id()/addpayment",
    meta: addpaymentDXArqP5HBnMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/addpayment.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-cancel-complete",
    path: "/plan/:id()/cancel/complete",
    meta: completesW3S5GIfwgMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/complete.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-cancel-confirm",
    path: "/plan/:id()/cancel/confirm",
    meta: confirmZaJN544jSMMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/confirm.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-cancel-notice",
    path: "/plan/:id()/cancel/notice",
    meta: noticeocPvnygYG8Meta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/notice.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-cancel-reason",
    path: "/plan/:id()/cancel/reason",
    meta: reasonPgWqfryCjvMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/reason.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-cancel-start",
    path: "/plan/:id()/cancel/start",
    meta: starthlhSNoMOrIMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/start.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-contract",
    path: "/plan/:id()/contract",
    meta: indexjvPGDBbHRdMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-contract-payment",
    path: "/plan/:id()/contract/payment",
    meta: indexh5wtssseCzMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-contract-payment-new",
    path: "/plan/:id()/contract/payment/new",
    meta: newZtC8agqjbyMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/payment/new.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-complete",
    path: "/plan/:id()/distribute/complete",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/complete.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-document-contentId-edit",
    path: "/plan/:id()/distribute/document/:contentId()/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/document/[contentId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-document-create",
    path: "/plan/:id()/distribute/document/create",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/document/create.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-live-contentId-edit",
    path: "/plan/:id()/distribute/live/:contentId()/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/live/[contentId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-live-create",
    path: "/plan/:id()/distribute/live/create",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/live/create.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-livearchive-contentId-edit",
    path: "/plan/:id()/distribute/livearchive/:contentId()/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/livearchive/[contentId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-movie-contentId-edit",
    path: "/plan/:id()/distribute/movie/:contentId()/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/movie/[contentId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-movie-create",
    path: "/plan/:id()/distribute/movie/create",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/movie/create.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-offer-campaignId-history",
    path: "/plan/:id()/distribute/offer/:campaignId()/history",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/[campaignId]/history.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-offer-create",
    path: "/plan/:id()/distribute/offer/create",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/create.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-distribute-offer-edit",
    path: "/plan/:id()/distribute/offer/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/edit.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-document-contentId",
    path: "/plan/:id()/document/:contentId()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/document/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-edit",
    path: "/plan/:id()/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id",
    path: "/plan/:id()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-addpayment",
    path: "/plan/:id()/join/addpayment",
    meta: addpaymentsq42VhWgW6Meta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/addpayment.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-agreement",
    path: "/plan/:id()/join/agreement",
    meta: agreementGFEsJTPCAHMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/agreement.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-authemail",
    path: "/plan/:id()/join/authemail",
    meta: authemailKy7LhKo6XiMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/authemail.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-complete",
    path: "/plan/:id()/join/complete",
    meta: complete0yCd5pXbWvMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/complete.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-confirm",
    path: "/plan/:id()/join/confirm",
    meta: confirmOHzc6ixkgiMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/confirm.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-documents",
    path: "/plan/:id()/join/documents",
    meta: documents9wlJZrMmxDMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/documents.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-payment-bank",
    path: "/plan/:id()/join/payment-bank",
    meta: payment_45bankDTelTLt7h2Meta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-bank.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-payment-card",
    path: "/plan/:id()/join/payment-card",
    meta: payment_45cardzpYvXwfe8UMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-card.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-payment-method",
    path: "/plan/:id()/join/payment-method",
    meta: payment_45methodWLUKCmDqo5Meta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-method.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-join-personalinfo",
    path: "/plan/:id()/join/personalinfo",
    meta: personalinfo07Z9455hNFMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/personalinfo.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-library-create",
    path: "/plan/:id()/library/create",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/library/create.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-library-edit",
    path: "/plan/:id()/library/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/library/edit.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-live-contentId-host",
    path: "/plan/:id()/live/:contentId()/host",
    meta: hosts4BLQaSyvrMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/host.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-live-contentId",
    path: "/plan/:id()/live/:contentId()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-live-contentId-result",
    path: "/plan/:id()/live/:contentId()/result",
    meta: result1CrO4LwJ8pMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/result.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-livearchive-contentId",
    path: "/plan/:id()/livearchive/:contentId()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/livearchive/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-liveschedule-contentId",
    path: "/plan/:id()/liveschedule/:contentId()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/liveschedule/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-movie-contentId-embed",
    path: "/plan/:id()/movie/:contentId()/embed",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/movie/[contentId]/embed.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-movie-contentId",
    path: "/plan/:id()/movie/:contentId()",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/movie/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-payment",
    path: "/plan/:id()/payment",
    meta: paymentpPMmpJbGdkMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-price-priceId-edit",
    path: "/plan/:id()/price/:priceId()/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/[priceId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-price-priceId-history",
    path: "/plan/:id()/price/:priceId()/history",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/[priceId]/history.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-price-create",
    path: "/plan/:id()/price/create",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/create.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-review-complete",
    path: "/plan/:id()/review/complete",
    meta: completecmR7yrUJ5PMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/review/complete.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-review",
    path: "/plan/:id()/review",
    meta: indexCTMb6bIQ4TMeta || {},
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/review/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-reviews",
    path: "/plan/:id()/reviews",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-talk-talkRoomId-edit",
    path: "/plan/:id()/talk/:talkRoomId()/edit",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/[talkRoomId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-talk-talkRoomId-editdm",
    path: "/plan/:id()/talk/:talkRoomId()/editdm",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/[talkRoomId]/editdm.vue").then(m => m.default || m)
  },
  {
    name: "plan-id-talk-create",
    path: "/plan/:id()/talk/create",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/create.vue").then(m => m.default || m)
  },
  {
    name: "plans-joining",
    path: "/plans/joining",
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plans/joining.vue").then(m => m.default || m)
  }
]