// ステータスコード
export enum LiveStatusType {
  None = "none",
  Start = "start",
  End = "end",
}

// ライブステータス
export class LiveStatus {
  constructor(
    public liveId: string,
    public status: string | LiveStatusType,
    public createdAt: Date | null,
  ) {}

  toString() {
    return this.liveId + ", " + this.status + ", " + this.createdAt;
  }
}

// Firestore data converter
export const LiveStatusConverter = {
  toFirestore: (item: any) => {
    return {
      liveId: item.liveId,
      status: item.status,
      createdAt: item.createdAt ?? new Date(),
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new LiveStatus(data.liveId, data.status, data.createdAt?.toDate());
  },
};
