export const consoleLog = (...data: any[]) => {
  if (useRuntimeConfig().public.appEnv !== "production") {
    console.log(...data);
  }
};

export const consoleWarn = (...data: any[]) => {
  if (useRuntimeConfig().public.appEnv !== "production") {
    console.warn(...data);
  }
};

export const consoleError = (...data: any[]) => {
  if (useRuntimeConfig().public.appEnv !== "production") {
    console.error(...data);
  }
};
