import * as Sentry from "@sentry/browser";
import { useLoginStore } from "~/stores/LoginStore";

// sentryの共通処理。ファイル名を*.global.tsにすると全ページ遷移時に処理が呼ばれる。
// ページ遷移時にユーザーIDをセットする
export default defineNuxtRouteMiddleware(() => {
  const loginStore = useLoginStore();
  Sentry.setUser({
    id: loginStore.loginInfo?.userId ?? undefined,
  });
});
