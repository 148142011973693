<script setup lang="ts">
  const error = useError();
  sentryErrorLog(`error.vue: statusCode=${error.value?.statusCode}, message=${error.value?.message}`, error);
</script>

<template>
  <div>
    <NuxtLayout>
      <OrganismsMainLayout>
        <div class="mx-auto" style="width: 90%">
          <v-card
            color="monochrome-04"
            class="mx-auto mt-8 mb-8 px-10 py-10 d-flex flex-column align-center content-width"
            width="90%"
          >
            <div class="error_part">
              <template v-if="error?.statusCode === 404">
                <div class="error_code">404</div>
                <div class="error_name">ページが見つかりません</div>
                <div class="error_message">
                  指定されたページが見つかりません。
                  <br />
                  下記のリンクからページをご覧ください。
                </div>
              </template>
              <template v-else-if="error?.statusCode === 500">
                <div class="error_code">500</div>
                <div class="error_name">問題が発生しました</div>
                <div class="error_message">
                  一時的にアクセスができない状況です。
                  <br />
                  時間を置いてから再度アクセスしてください。
                </div>
              </template>
              <template v-else>
                <div class="error_code">{{ error?.statusCode }}</div>
                <div class="error_name">エラーが発生しました</div>
              </template>
            </div>
            <v-row justify="center" class="my-4">
              <v-col cols="12" align="center">
                <AtomsButton href="/" class="go_to_top">トップページに戻る</AtomsButton>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </OrganismsMainLayout>
    </NuxtLayout>
  </div>
</template>

<style lang="scss" scoped>
  @import "assets/media.scss";
  .error_part {
    width: 400px;
    text-align: center;
  }
  .error_code {
    font-size: 36px;
    font-weight: 700;
  }
  .error_name {
    font-size: 25px;
    font-weight: 700;
    border-bottom: 1px solid #aaaaaa;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .error_message {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
  }
  .go_to_top {
  }
  @include display_sp {
    .error_part {
      width: auto;
    }
    .error_code {
      font-size: 36px;
    }
    .error_name {
      font-size: 19px;
    }
    .error_message {
      // text-align: left;
      font-size: 15px;
    }
    .go_to_top {
    }
  }
</style>
