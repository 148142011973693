import { getToken, deleteToken } from "firebase/messaging";
import type { IPushNotificationRepository } from "~/interfaces/IPushNotificationRepository";
import type { IHttpResponse } from "~/interfaces/IHttpResponse";

export class PushNotificationRepository implements IPushNotificationRepository {
  /**
   * service workerを登録する
   */
  async registerServiceWorker(): Promise<void> {
    if ("serviceWorker" in navigator) {
      const registration = await navigator.serviceWorker.getRegistration();
      if (!registration) {
        // service workerが登録されていない場合は登録する
        await navigator.serviceWorker.register("/sw.js", { scope: "/" });
      }
    }
  }

  /**
   * service workerがactiveかどうかを返す
   * @returns
   */
  async isActiveServiceWorker(): Promise<boolean> {
    if ("serviceWorker" in navigator) {
      const registration = await navigator.serviceWorker.getRegistration();
      return !!(registration && registration.active);
    }
    return false;
  }

  /**
   * デバイストークンを取得する
   * @param showRequest 通知許可を求めるかどうか
   * @returns デバイストークン
   */
  async getDeviceToken(showRequest: boolean): Promise<string> {
    const { $messaging, $firebaseConfig } = useNuxtApp();
    // 一度deniedにしてしまうとrequestPermissionでは取得できなくなるので、requestPermissionの前にpermissionを取得する
    let permission = Notification.permission;
    if (permission === "default" && showRequest) {
      // 通知許可を求める。システムダイアログが開く。
      permission = await Notification.requestPermission();
    }
    if (permission !== "granted") {
      throw new Error(`Notification.permission is ${permission}`);
    }
    // サービスワーカーがactiveではないことがあるので起動する
    await this.registerServiceWorker();
    // サービスワーカーを取得してfirebaseのgetTokenに渡す
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      throw new ServiceWorkerNotActiveError(`Service Worker is not registered`);
    }
    if (!registration.active) {
      throw new ServiceWorkerNotActiveError(`Service Worker is not active`);
    }

    const token = await getToken($messaging, {
      vapidKey: $firebaseConfig.vapidKey,
      serviceWorkerRegistration: registration,
    });
    return token;
  }

  /**
   * デバイストークンを削除する
   */
  async deleteDeviceToken(): Promise<void> {
    const { $messaging } = useNuxtApp();
    await deleteToken($messaging);
  }

  /**
   * デバイストークンを登録する
   */
  async registerDeviceToken(token: string): Promise<void> {
    const body = {
      device_token: token,
    };

    const res = await PostRequest<IHttpResponse>("/device_token", JSON.stringify(body), true);

    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed post /device_token: response=${JSON.stringify(res)}`);
    }
  }

  /**
   * プッシュ通知を登録する
   */
  async registerPushNotification(planId: string): Promise<void> {
    const body = {
      plan_id: planId,
    };

    const res = await PostRequest<IHttpResponse>("/web_push/plan", JSON.stringify(body));

    if (!res || res.statusCode !== 200) {
      throw new Error(
        `Failed post /web_push/plan: planId=${planId}, response=${JSON.stringify(res)}`,
      );
    }
  }

  /**
   * プッシュ通知を解除する
   */
  async unregisterPushNotification(planId: string): Promise<void> {
    const res = await DeleteRequest<IHttpResponse>(`/web_push/plan/${planId}`);

    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed delete /web_push/plan/${planId}: response=${JSON.stringify(res)}`);
    }
  }
}
