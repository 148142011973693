import { defineStore } from "pinia";
import { PlanInfo } from "~/entities/PlanInfo";
import { DistributionFile } from "~/entities/DistributionFile";
import { type IPlanRepository } from "~/interfaces/IPlanRepository";
import { PlanRepository } from "~/Repositories/PlanRepository";

export const usePlanEditStore = defineStore("PlanEditStore", () => {
  const loading = ref<boolean>(false);
  const editPlan = ref<PlanInfo>(); // 編集中のプランデータ
  const editBlocks = ref<{ id: number; text: string }[]>([]); // 編集中のブロック
  const thumbnailFile = ref<DistributionFile>(new DistributionFile()); // サムネイルファイル

  // オーナー情報
  const ownerContent = ref<string[]>([]);
  // マークダウンの見出しで区切ったコンテンツ
  const dividedContents = ref<any[]>([]);

  const repository: IPlanRepository = new PlanRepository();

  const joinIntroduction = () => {
    let introduction = ownerContent.value.reduce((acc, cur) => acc + cur.value, "");
    if (dividedContents.value.length > 0) {
      introduction += dividedContents.value
        .map(contents => {
          return contents.map(content => content.value ?? "").join("");
        })
        .join("");
    }
    return introduction;
  };

  const joinSubIntroduction = () => {
    let introduction = "";
    if (editBlocks.value.length > 0) {
      introduction += editBlocks.value.map(block => "<PlanEdit/>" + block.text).join("");
    }
    return introduction;
  };

  const patchPlan = async () => {
    loading.value = true;
    try {
      const introduction = joinIntroduction();
      const subIntroduction = joinSubIntroduction();
      const plan = JSON.parse(JSON.stringify(editPlan.value));
      plan.introduction = introduction;
      plan.subIntroduction = subIntroduction;
      if (thumbnailFile.value.fileKey) {
        plan.thumbnailImage = thumbnailFile.value.fileKey;
      }
      await repository.patchPlan(plan);
    } finally {
      loading.value = false;
    }
  };

  const uploadImageFile = async (file: File): Promise<string> => {
    const convertedFile = await convertContentImage(file);
    const res = await repository.uploadImageFile(convertedFile);
    return res.fileUrl;
  };

  const uploadPlanDetailImageFile = async (file: File): Promise<string> => {
    const convertedFile = await convertPlanDetailImage(file);
    const res = await repository.uploadImageFile(convertedFile);
    return res.fileUrl;
  };

  const addBlock = (id?: number, text?: string) => {
    const maxId = editBlocks.value.reduce((acc, block) => Math.max(acc, block.id), 0);
    const index = editBlocks.value.findIndex(block => block.id === id);
    const addText = text || "<h3><strong>見出し</strong></h3><p></p><p>本文</p>";
    if (index >= 0) {
      editBlocks.value.splice(index, 0, { id: maxId + 1, text: addText });
    } else {
      editBlocks.value.push({ id: maxId + 1, text: addText });
    }
  };

  const removeBlock = (id: number) => {
    editBlocks.value = editBlocks.value.filter(block => block.id !== id);
  };

  const sortBlock = (id: number, addIndex: number) => {
    const from = editBlocks.value.findIndex(block => block.id === id);
    const to = from + addIndex;
    // toが0未満の場合、またはfromとtoが同じ場合、またはlengthを超える場合は何もしない
    if (to < 0 || from === to || from >= editBlocks.value.length || to >= editBlocks.value.length) return;
    const blocks = JSON.parse(JSON.stringify(editBlocks.value));
    const block = blocks.splice(from, 1)[0];
    blocks.splice(to, 0, block);
    editBlocks.value = blocks;
  };

  const editStart = (planInfo: PlanInfo) => {
    editPlan.value = JSON.parse(JSON.stringify(planInfo));
    editBlocks.value = [];
    // 表示するためpreviewImgSrcにurlを入れておく
    thumbnailFile.value = new DistributionFile("", planInfo.thumbnailImage, planInfo.thumbnailImage);
  };

  const reset = () => {
    editPlan.value = undefined;
    editBlocks.value = [];
    ownerContent.value = [];
    dividedContents.value = [];
    thumbnailFile.value = new DistributionFile();
  };

  const isEditing = computed(() => !!editPlan.value);

  return {
    loading,
    editPlan,
    editBlocks,
    ownerContent,
    dividedContents,
    thumbnailFile,
    patchPlan,
    uploadImageFile,
    uploadPlanDetailImageFile,
    addBlock,
    removeBlock,
    sortBlock,
    editStart,
    reset,
    isEditing,
  };
});
