export type NotificationSettingNotificationType = "webpush" | "line";
export type NotificationSettingContentsType = "live_movie" | "movie" | "document" | "talk_room";

export class NotificationSettingItem {
  constructor(
    public notificationType: NotificationSettingNotificationType,
    public contentsType: NotificationSettingContentsType,
    public settingName: string,
    public roomId: number | null,
    public enable: boolean,
  ) {}

  static forPutContentsSetting(
    notificationType: NotificationSettingNotificationType,
    contentsType: NotificationSettingContentsType,
  ) {
    return new NotificationSettingItem(notificationType, contentsType, "", 0, true);
  }

  static forPutTalkRoomSetting(
    notificationType: NotificationSettingNotificationType,
    roomId: number | null,
  ) {
    return new NotificationSettingItem(notificationType, "talk_room", "", roomId, true);
  }
}

export class NotificationSettings {
  constructor(
    public webpushSettings: NotificationSettingItem[],
    public lineSettings: NotificationSettingItem[],
  ) {}
}
