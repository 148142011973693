import snakecaseKeys from "snakecase-keys";
import { type IPersonalInfoRepository } from "~/interfaces/IPersonalInfoRepository";
import { PersonalInfo } from "~/entities/PersonalInfo";
import type { TransferInfo } from "~/entities/TransferInfo";
import type { TransferInfoResponse } from "~/entities/TransferInfoResponse";

export class PersonalInfoRepository implements IPersonalInfoRepository {
  async get(planId?: string, userRole?: string): Promise<PersonalInfo> {
    const paramsObj: { [name: string]: string } = {};
    if (planId !== undefined) {
      paramsObj.plan_id = planId;
    }
    if (userRole !== undefined) {
      paramsObj.user_role = userRole;
    }
    const searchParams = new URLSearchParams(paramsObj);

    // // ログインユーザーの個人情報取得
    const res = await GetRequest<{ statusCode: number; profile: PersonalInfo }>(`/user?` + searchParams.toString());
    if (!res || res.statusCode !== 200) {
      throw new Error("PersonalInfoRepository.get API Error");
    }
    return res.profile;
  }

  async save(personalInfo: PersonalInfo): Promise<PersonalInfo> {
    // 保存後の個人情報を返却
    const res = await PutRequest<{ statusCode: number }>(
      `/user`,
      JSON.stringify(snakecaseKeys(personalInfo as Record<string, unknown>, { deep: true })),
      true, // 共通エラー処理のダイアログを表示しない
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("PersonalInfoRepository.save API Error");
    }
    return personalInfo;
  }

  async saveTransfer(transferInfo: TransferInfo): Promise<TransferInfoResponse> {
    // 保存後の個人情報及び振込情報を登録する
    const res = await PutRequest<TransferInfoResponse>(
      `/user/transfer`,
      JSON.stringify(snakecaseKeys(transferInfo as Record<string, unknown>, { deep: true })),
      true, // 共通エラー処理のダイアログを表示しない
    );
    if (!res || !((res.bankTransferId || 0) > 0)) {
      throw new Error("PersonalInfoRepository.saveTransfer API Error");
    }
    return res;
  }

  async updateEmail(email: string): Promise<void> {
    // FIXME: 互換性のために入れている。APIが完成したら削除する
    const returnUrl = "/";
    // メールアドレス更新
    const res = await PostRequest<{ statusCode: number }>(
      `/user/update_email`,
      JSON.stringify(snakecaseKeys({ email, returnUrl })),
      true, // 共通エラー処理のダイアログを表示しない
    );
    if (!res || res.statusCode !== 200) {
      // TODO: 認証エラー検証用にエラーメッセージを表示している。リリース前に消すこと
      throw new Error("PersonalInfoRepository.updateEmail API Error " + res.message?.description);
    }
  }

  async authEmail(mailAuthKey: string): Promise<string> {
    // メールアドレス認証
    const res = await PostRequest<{ statusCode: number; returnUrl: string }>(
      `/user/update_email_auth`,
      JSON.stringify(snakecaseKeys({ mailAuthKey })),
      true, // 共通エラー処理のダイアログを表示しない
    );
    if (!res || res.statusCode !== 200) {
      // TODO: 認証エラー検証用にエラーメッセージを表示している。リリース前に消すこと
      throw new Error("PersonalInfoRepository.authEmail API Error " + res.message?.description);
    }
    return res.returnUrl;
  }

  async searchAddress(zipcode: string): Promise<any> {
    const { data, error } = await useFetch(`https://api.zipaddress.net/?zipcode=${zipcode}`, {
      method: "get",
    });
    if (error.value) {
      // エラーの場合
      return null;
    } else {
      return data.value.data;
    }
  }
}
