import type { CampaignResponse } from "~/entities/CampaignResponse";
import type { ICampaignRepository, ICampaignSaveBody } from "~/interfaces/ICampaignRepository";

export class CampaignRepository implements ICampaignRepository {
  getCampaigns(options: Record<string, any>): Promise<CampaignResponse> {
    const paramsObj: { [name: string]: string } = {
      ...filterEmptyObject(options || {}),
    };
    const searchParams = new URLSearchParams(paramsObj);
    return GetRequest<CampaignResponse>(`/campaign_manage?` + searchParams.toString());
  }

  getCampaignHistory(campaignId: string): Promise<CampaignResponse> {
    return GetRequest<CampaignResponse>(`/campaign_manage/history/${campaignId}`);
  }

  createCampaign(body: ICampaignSaveBody): Promise<string> {
    return PostRequest<string>(`/campaign_manage`, JSON.stringify(body));
  }

  updateCampaign(campaignId: string, body: ICampaignSaveBody): Promise<any> {
    return PutRequest<string>(`/campaign_manage/${campaignId}`, JSON.stringify(body));
  }

  deleteCampaign(campaignId: string): Promise<boolean> {
    return DeleteRequest<boolean>(`/campaign_manage/${campaignId}`);
  }
}
