import { defineStore } from "pinia";
import { UserInfo } from "~/entities/UserInfo";
import { ContractMember } from "~/entities/ContractMember";
import { useLoginStore } from "~/stores/LoginStore";
import { useSnackBarStore } from "~/stores/SnackBarStore";
import type { IUserRepository } from "~/interfaces/IUserRepository";
import { UserRepository } from "~/Repositories/UserRepository";

export const useUserStore = defineStore("UserStore", () => {
  const repository: IUserRepository = new UserRepository();

  const user = ref<UserInfo>();
  const otherUser = ref<UserInfo>();
  const loading = ref(false);

  const loginStore = useLoginStore();

  /**
   * 自分のユーザーの情報を取得する
   */
  const fetch = async (withoutLoading = false) => {
    if (!withoutLoading) loading.value = true;
    user.value = undefined;
    try {
      if (loginStore.loginInfo?.userId) {
        // ユーザー情報を取得する
        user.value = await repository.fetch(loginStore.loginInfo?.userId);
      }
    } finally {
      if (!withoutLoading) loading.value = false;
    }
  };

  /**
   * 指定のユーザーの情報を取得する
   */
  const fetchOther = async (userId: string, withoutLoading: boolean) => {
    if (!withoutLoading) loading.value = true;
    otherUser.value = undefined;
    try {
      otherUser.value = await repository.fetch(userId);
    } finally {
      if (!withoutLoading) loading.value = false;
    }
  };

  /**
   * ユーザーのstateを更新する
   * @param userInfo
   */
  const setUserInfo = (userInfo: UserInfo) => {
    user.value = userInfo;
  };

  /**
   * ユーザーの情報の更新処理をAPIに投げる
   */
  const update = async (userInfo: UserInfo) => {
    loading.value = true;
    try {
      await repository.update(userInfo);
    } finally {
      loading.value = false;
    }
  };

  /**
   * ユーザーのアイコンの更新処理をAPIに投げる
   */
  const uploadIcon = async (imageFile: File) => {
    loading.value = true;
    try {
      await repository.uploadIcon(imageFile);
    } finally {
      loading.value = false;
    }
  };

  /**
   * ユーザーの契約情報を取得する
   */
  const contractInfo = async (planId: string, noErrorDialog?: boolean): Promise<ContractMember> => {
    return await repository.contractInfo(planId, noErrorDialog);
  };
  /**
   * 投稿禁止かチェックし、禁止状態であればエラーメッセージを表示する
   * @returns
   */
  const isPostProhibited = (): boolean => {
    if (!user.value) {
      // ユーザーデータを取得してない場合はメッセージなしだが、投稿禁止として扱う
      return true;
    }
    if (user.value?.isPostProhibited) {
      const snackBarStore = useSnackBarStore();
      snackBarStore.set("利用規約違反のため、投稿機能が一時的に制限されています", "error");
      return true;
    }
    return false;
  };

  return {
    user,
    otherUser,
    loading,
    fetch,
    fetchOther,
    setUserInfo,
    update,
    uploadIcon,
    contractInfo,
    isPostProhibited,
  };
});
