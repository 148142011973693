import snakecaseKeys from "snakecase-keys";
import type { ILiveRepository } from "~/interfaces/ILiveRepository";
import { StreamingPrepareResponse } from "~/entities/StreamingPrepareResponse";
import { HttpCommonResponse } from "~/entities/HttpCommonResponse";
import { VideoStreamingTime } from "~/entities/VideoStreamingTime";

export class LiveRepository implements ILiveRepository {
  /**
   * 配信準備
   */
  async streamingPrepare(liveId: string): Promise<StreamingPrepareResponse> {
    const body = {
      contents_id: liveId,
    };
    const res = await PostRequest<StreamingPrepareResponse>(
      `/streaming/prepare`,
      JSON.stringify(body),
    );
    if (!res || res.statusCode !== 200 || !res.agoraInfo) {
      throw new Error(`Failed post /streaming/prepare: response=${JSON.stringify(res)}`);
    }
    return res;
  }

  /**
   * 配信開始
   */
  async streamingStart(liveId: string, videoStreaming: boolean): Promise<void> {
    const body = {
      contents_id: liveId,
      video_streaming: videoStreaming,
    };
    const res = await PostRequest<HttpCommonResponse>(`/streaming/start`, JSON.stringify(body));
    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed post /streaming/start: response=${JSON.stringify(res)}`);
    }
  }

  /**
   * 配信終了
   */
  async streamingEnd(liveId: string): Promise<void> {
    const body = {
      contents_id: liveId,
    };
    const res = await PostRequest<HttpCommonResponse>(`/streaming/end`, JSON.stringify(body));
    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed post /streaming/end: response=${JSON.stringify(res)}`);
    }
  }

  /**
   * ビデオミュート切り替えフラグの送信
   */
  async postVideoStreamingTimes(
    liveId: string,
    videoStreamingTimes: VideoStreamingTime[],
  ): Promise<HttpCommonResponse> {
    const body = {
      contents_id: liveId,
      video_streaming_times: videoStreamingTimes,
    };
    const response = await PostRequest<HttpCommonResponse>(
      `/streaming/video_streaming_times`,
      JSON.stringify(snakecaseKeys(body, { deep: true })),
    );
    return response;
  }
}
