import type { ILineLinkRepository } from "~/interfaces/ILineLinkRepository";
import { LineLink } from "~/entities/LineLink";
import { LineLinkInfo } from "~/entities/LineLinkInfo";
import { LineLinkResult } from "~/entities/LineLinkResult";

export class LineLinkRepository implements ILineLinkRepository {
  /**
   * LINE連携情報を取得する
   */
  async fetch(): Promise<LineLinkInfo> {
    const lineLink = await GetRequest<LineLink>(`/line_link`);
    return lineLink.lineLinkResponseData;
  }

  /**
   * LINE連携コードを登録する
   */
  async link(code: string, state: string): Promise<LineLinkResult> {
    const body = {
      code: code,
      state: state,
    };

    const res = await PostRequest<LineLinkResult>("/line_link", JSON.stringify(body), true);

    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed post /line_link: response=${JSON.stringify(res)}`);
    }

    return res;
  }

  /**
   * LINE連携を解除する
   */
  async linkRevoke(): Promise<LineLinkResult> {
    const res = await DeleteRequest<LineLinkResult>("/line_link");

    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed delete /line_link: response=${JSON.stringify(res)}`);
    }

    return res;
  }

  /**
   * Planの購読
   */
  async subscribePlan(planId: string): Promise<LineLinkResult> {
    const body = {
      plan_id: planId,
    };

    const res = await PostRequest<LineLinkResult>("/line_link/plan", JSON.stringify(body));
    if (!res || res.statusCode !== 200) {
      throw new Error(
        `Failed post /line_link/plan: planId=${planId}, response=${JSON.stringify(res)}`,
      );
    }

    return res;
  }

  /**
   * Planの購読解除
   */
  async unsubscribePlan(planId: string): Promise<LineLinkResult> {
    const res = await DeleteRequest<LineLinkResult>(`/line_link/plan/${planId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed delete /line_link/plan/${planId}: response=${JSON.stringify(res)}`);
    }

    return res;
  }
}
