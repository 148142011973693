import { serverTimestamp } from "firebase/firestore";

// メッセータイプ 通常メッセージ, 入退室メッセージ
export type messageType = "message" | "access";
// ライブチャットデータ
export class LiveChatMessage {
  constructor(
    public id: string,
    public userId: string,
    public userImage: string,
    public userName: string,
    public text: string,
    public type: messageType,
    public elapsedTime: string,
    public createdAt: Date | null,
  ) {}

  toString() {
    return (
      this.userId +
      ", " +
      this.userImage +
      ", " +
      this.userName +
      ", " +
      this.text +
      ", " +
      this.type +
      ", " +
      this.elapsedTime +
      ", " +
      this.createdAt
    );
  }
}

// Firestore data converter
export const LiveChatMessageConverter = {
  toFirestore: (item: any) => {
    return {
      userId: item.userId,
      userImage: item.userImage,
      userName: item.userName,
      text: item.text,
      type: item.type,
      elapsedTime: item.elapsedTime,
      createdAt: item.createdAt ?? serverTimestamp(),
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new LiveChatMessage(
      snapshot.id,
      data.userId,
      data.userImage,
      data.userName,
      data.text,
      data.type,
      data.elapsedTime,
      data.createdAt?.toDate(),
    );
  },
};
