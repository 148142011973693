<script setup lang="ts">
  import { type ICON_TYPE } from "~/components/Atoms/MenuIcon.vue";

  interface Props {
    title?: string; // 表示する文字
    img?: string; // 画像を表示場合は設定する
    icon?: ICON_TYPE; // アイコンを表示場合は設定する
    toPath?: string; // リンク先
    loading?: boolean; // ローディング中アニメーションを表示するかどうか
    mypage?: boolean; // マイページかどうか
  }
  const props = withDefaults(defineProps<Props>(), {
    title: undefined,
    img: undefined,
    icon: undefined,
    toPath: "",
    loading: false,
    mypage: false,
  });

  // 内部リンクかどうかを返す
  const isInternalLink = computed<boolean>(() => {
    return !/^https?:\/\//.test(props.toPath);
  });

  const isTabletAndSp = isTabletAndSpDisplayRef();
  const fontSize = computed<string>(() => {
    return isTabletAndSp.value && props.icon ? "8px" : "16px";
  });
  const lineHeight = computed<string>(() => {
    return isTabletAndSp.value && props.icon ? "11px" : "24px";
  });
  const onClick = () => {
    if (isInternalLink) {
      navigateTo(props.toPath);
    } else {
      window.open(props.toPath, "_blank");
    }
  };
</script>

<template>
  <div class="text-subtitle-1 font-weight-black" style="cursor: pointer" @click="onClick">
    <div class="d-flex justify-start align-center" :class="{ 'flex-column': isTabletAndSp }">
      <template v-if="loading">
        <AtomsLoadingSkeleton v-if="img" class="rounded-circle mx-2 my-0" :width="36" :height="36" />
      </template>
      <template v-else>
        <AtomsUserIcon
          v-if="img"
          :src="img"
          :max-width="36"
          :min-width="36"
          :width="36"
          :height="36"
          class="mx-2 my-0"
        />
        <AtomsMenuIcon
          v-if="icon"
          :icon="icon"
          :max-width="24"
          :min-width="24"
          :width="24"
          :height="24"
          class="mx-2 my-0"
        />
        <span v-if="title" class="title-text text-truncate">{{ title }}</span>
      </template>
    </div>
    <div v-if="mypage && !loading" class="mypage">マイページ</div>
  </div>
</template>

<style lang="scss" scoped>
  @import "assets/media.scss";
  .title-text {
    font-size: v-bind(fontSize);
    font-weight: 700;
    line-height: v-bind(lineHeight);
  }
  .mypage {
    font-size: 10px;
    text-align: center;
    height: 20px;
    line-height: 1.8em;
    letter-spacing: 0em;
    font-weight: 400;
    .mypage-link {
      color: white;
      text-decoration: none;
    }
    @include display_sp {
      font-size: 8px;
      font-weight: 400;
      letter-spacing: 0.06em;
    }
  }
</style>
