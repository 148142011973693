import type { ILibraryRepository } from "~/interfaces/ILibraryRepository";
import { Library } from "~/entities/Library";
import { LibraryContent } from "~/entities/LibraryContent";
import { LibraryResponse } from "~/entities/LibraryResponse";

export class LibraryRepository implements ILibraryRepository {
  /**
   * ライブラリを取得する
   * @param planId プランID
   * @param libraryId ライブラリID
   */
  async getLibrary(libraryId: string, page: number, pageSize: number): Promise<Library> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: pageSize.toString(),
    };
    const searchParams = new URLSearchParams(paramsObj);
    return await GetRequest<Library>(`/library/${libraryId}?` + searchParams.toString());
  }

  /**
   * ライブラリを追加する
   * @param planId プランID
   * @param library ライブラリ
   */
  async createLibrary(
    planId: string,
    library: Library,
    libraryContents: LibraryContent[],
  ): Promise<string> {
    return await PostRequest<string>(
      `/library/${planId}`,
      JSON.stringify({
        library_title: library.libraryTitle,
        library_summary: library.librarySummary,
        display_order: library.displayOrder || 0,
        contents: libraryContents.map(lc => {
          return {
            contents_id: lc.id,
            display_order: lc.displayOrder || 0,
          };
        }),
      }),
    );
  }

  /**
   * ライブラリを更新する
   * @param planId プランID
   * @param library ライブラリ
   */
  async updateLibrary(
    planId: string,
    library: Library,
    libraryContents: LibraryContent[],
  ): Promise<boolean> {
    return await PutRequest<boolean>(
      `/library/${planId}/${library.libraryId}`,
      JSON.stringify({
        library_title: library.libraryTitle,
        library_summary: library.librarySummary,
        display_order: library.displayOrder || 0,
        contents: libraryContents.map((lc, index) => {
          return {
            contents_id: lc.id,
            display_order: index,
          };
        }),
      }),
    );
  }

  /**
   * ライブラリを削除する
   * @param planId プランID
   * @param libraryId ライブラリID
   */
  async deleteLibrary(planId: string, libraryId: string): Promise<boolean> {
    return await DeleteRequest<boolean>(`/library/${planId}/${libraryId}`);
  }

  /**
   * ライブラリBrowse
   * @param library_id: string
   */
  async browseLibrary(libraryId: string): Promise<Library> {
    return await PostRequest<Library>(`/library/browse/${libraryId}`);
  }

  /**
   * ソート順更新
   * @param planId プランID
   * @param libraries[] ライブラリ
   */
  async updateDisplaySort(planId: string, libraryIds: string[]): Promise<boolean> {
    return await PatchRequest<boolean>(
      `/library/${planId}/sort`,
      JSON.stringify({
        library_ids: libraryIds,
      }),
    );
  }

  /**
   * ライブラリ一覧を取得する
   * @param planId プランID
   */
  async getLibraries(planId: string): Promise<LibraryResponse> {
    return await GetRequest<LibraryResponse>(`/library/list/${planId}`);
  }
}
