<!-- サイドメニュー -->

<script setup lang="ts">
  import { useDisplay } from "vuetify";

  interface Props {
    app?: boolean;
  }
  withDefaults(defineProps<Props>(), {
    app: true,
  });

  const items = ref([
    {
      title: "Fincsとは",
      link: "https://fincs.jp/hp/",
    },
    {
      title: "利用規約",
      link: "https://fincs.jp/hp/terms",
    },
    {
      title: "プライバシーポリシー",
      link: "https://fincs.jp/hp/privacy",
    },
    {
      title: "特定商取引法に基づく表記",
      link: "https://fincs.jp/hp/commercial-transactions-act",
    },
    {
      title: "運営からのお知らせ",
      link: "https://fincs.jp/hp/information",
    },
    {
      title: "よくあるご質問",
      link: "https://fincs.jp/hp/faq",
    },
  ]);

  // レスポンシブ対応
  const { name } = useDisplay();
  const showSPFooter = computed<boolean>(() => name.value === "xs");
</script>

<template>
  <v-footer class="" :app="app" color="background">
    <div v-if="showSPFooter" class="mb-8">
      <div v-for="link in items" :key="link.title">
        <v-btn color="transparent" variant="text" link :href="link.link" target="_blank">
          <div class="text-white footer-text">
            {{ link.title }}
          </div>
        </v-btn>
      </div>
    </div>
    <v-row v-else class="mb-8" justify="center" no-gutters>
      <v-col class="text-center mt-4 d-flex justify-center align-center flex-wrap" cols="12">
        <v-btn
          v-for="link in items"
          :key="link.title"
          color="transparent"
          variant="text"
          link
          :href="link.link"
          target="_blank"
        >
          <span class="text-white footer-text">
            {{ link.title }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style lang="scss" scoped>
  .footer-text {
    font-size: 14px;
    font-weight: 400;
  }
</style>
