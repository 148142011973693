<script setup lang="ts">
  import { PlanInfo } from "~/entities/PlanInfo";

  // line
  import { useLineLinkStore } from "~/stores/LineLinkStore";
  import { useLineLinkStatusStore } from "~/stores/LineLinkStatusStore";
  import { LineLinkStatusType } from "~/entities/LineLinkStatus";
  import { usePlanStore } from "~/stores/PlanStore";
  import { useLoginStore } from "~/stores/LoginStore";
  import { useSnackBarStore } from "~/stores/SnackBarStore";

  const planStore = usePlanStore();
  const loginStore = useLoginStore();
  const snackBarStore = useSnackBarStore();

  interface Props {
    planInfo: PlanInfo;
  }
  const props = withDefaults(defineProps<Props>(), {
    planInfo: undefined,
  });

  // line購読処理
  const lineLinkStore = useLineLinkStore();
  // line連携状態監視
  const lineLinkStatusStore = useLineLinkStatusStore();
  // Line購読
  const lineSubscrabe = async () => {
    if (loginStore.isLogin() === false) {
      snackBarStore.set("Line連携するにはログインしてください", "error");
      return;
    }
    await lineLinkStore.fetch();
    // Line連携してなければLine連携、していればそのままPlan購読
    if (lineLinkStore.lineLinkInfo?.isLinked) {
      try {
        await lineLinkStore.subscribePlan(props.planInfo?.id.toString());
        snackBarStore.set(`${props.planInfo?.title}のLINE通知連携が完了しました`);
        await planStore.fetchOne(props.planInfo?.id.toString());
      } catch (e) {
        // 失敗時はエラーを表示する
        snackBarStore.setError("LINE通知連携に失敗しました");
        sentryErrorLog(`LINE通知連携に失敗: ${e.message}`);
      }
    } else {
      // line連携処理を監視する
      lineLinkStatusStore.set(loginStore.loginInfo.userId, "", LineLinkStatusType.Start, "", "");
      lineLinkStatusStore.startObserve(loginStore.loginInfo.userId);
      // 別ウィンドウでline連携処理を開始する
      lineLinkStore.openLink(props.planInfo?.id.toString());
    }
  };
  // Line解除
  const lineUnSubscrabe = async () => {
    try {
      await lineLinkStore.unsubscribePlan(props.planInfo?.id.toString());
      snackBarStore.set(`${props.planInfo?.title}のLINE通知連携を解除しました`);
      await planStore.fetchOne(props.planInfo?.id.toString());
    } catch (e) {
      // 失敗時はエラーを表示する
      snackBarStore.setError("LINE通知連携の解除に失敗しました");
      sentryErrorLog(`LINE通知連携の解除に失敗: ${e.message}`);
    }
  };

  onMounted(() => {
    // lineの連携情報を見たいだけなのでlineLinkInfoが取得済みであれば取得しない
    if (loginStore.isLogin() && !lineLinkStore.lineLinkInfo) {
      lineLinkStore.fetch();
    }
  });

  onUnmounted(() => {
    // line連携処理の監視を停止する
    lineLinkStatusStore.stopObserve();
  });

  // line連携ボタンの表示判定
  const showContentType = computed<"none" | "btn" | "info">(() => {
    if (!lineLinkStore.lineLinkInfo) {
      // line連携情報が取得できてない状態であれば何も表示しない
      // loadingでの判定ではボタンの押下ごとに表示が切り替わるためlineLinkInfoで判定する
      return "none";
    }
    if (!loginStore.isLogin()) {
      // 未ログインではline連携状態を取れないのでボタンを表示する
      return "btn";
    }
    if (lineLinkStore.lineLinkInfo?.isLinked) {
      // line連携済みの場合は表示する
      return "btn";
    }

    if (isIOS() && isPWA()) {
      // iOSのPWAでの警告表示
      return "info";
    }
    // 通常はボタンを表示する
    return "btn";
  });

  // line連携処理を監視する
  watch(
    () => {
      return lineLinkStatusStore.lineLinkStatus;
    },
    async () => {
      if (lineLinkStatusStore.lineLinkStatus?.status === LineLinkStatusType.Success) {
        // line連携処理の監視を停止する
        lineLinkStatusStore.stopObserve();
        // 完了を表示
        snackBarStore.set(`${props.planInfo?.title}のLINE通知連携が完了しました`);
        // リロード
        await planStore.fetchOne(props.planInfo?.id.toString());
      } else if (lineLinkStatusStore.lineLinkStatus?.status === LineLinkStatusType.Failed) {
        // line連携処理の監視を停止する
        lineLinkStatusStore.stopObserve();
        // 失敗時はエラーを表示する
        snackBarStore.setError("LINE通知連携に失敗しました");
      } else if (lineLinkStatusStore.lineLinkStatus?.status === LineLinkStatusType.Canceled) {
        // line連携処理の監視を停止する
        lineLinkStatusStore.stopObserve();
        // 失敗時はエラーを表示する
        snackBarStore.setError("LINE通知連携に失敗しました");
      }
    },
  );
</script>

<template>
  <div class="line-btn-wrapper" data-testid="line-notification-btn">
    <template v-if="showContentType === 'btn'">
      <v-btn
        v-if="planInfo?.isLineLinked"
        size="small"
        class="line-btn line-btn-disactive text-center rounded px-2"
        @click="lineUnSubscrabe"
      >
        <img src="@/assets/images/icon/line.svg" class="mr-1" :width="24" alt="line" />
        講座の通知を解除する
      </v-btn>
      <v-btn v-else size="small" class="line-btn text-center bg-line-link rounded px-2" @click="lineSubscrabe">
        <img src="@/assets/images/icon/line.svg" class="mr-1" :width="24" alt="line" />
        <span class="text-white">講座の通知を受け取る</span>
      </v-btn>
    </template>
    <template v-else-if="showContentType === 'info'">
      <div class="info-wrapper">
        <div class="text-center info-title mb-1">LINE通知の受け取り方法</div>
        <div class="text-left info-text">
          WebアプリからLINE通知を受け取ることができません。<br />
          他のブラウザを開いてLINE通知受け取り登録をしてください。<br /><br />
          他のブラウザでLINE通知受け取り登録が完了すると、こちらで各種設定が可能です。<br />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .line-btn {
    width: 280px;
    height: 56px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    &.line-btn-disactive {
      background-color: #aaaaaa;
    }
  }
  .info-wrapper {
    padding: 0 12px;
    .info-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
    }
    .info-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
    }
  }
</style>
