import { useDisplay } from "vuetify";

export const isSpDisplayRef = (): Ref<boolean> => {
  const { name } = useDisplay();
  return computed<boolean>(() => name.value === "xs");
};

export const isTabletAndSpDisplayRef = (): Ref<boolean> => {
  const { name } = useDisplay();
  return computed<boolean>(() => name.value === "xs" || name.value === "sm");
};

// コンテンツカードの横幅
export const getContentCardWidth = (): Ref<number> => {
  return computed<number>(() => {
    return Math.min(330, window.screen.width - 48);
  });
};

// オーナーカードの横幅
export const getOwnerCardWidth = (): Ref<number> => {
  return computed<number>(() => {
    return Math.min(330, window.screen.width - 48);
  });
};

// 講座カードの横幅
export const getPlanCardWidth = (): Ref<number> => {
  return computed<number>(() => {
    return Math.min(330, window.screen.width - 48);
  });
};

export const requestFullScreen = (element: any) => {
  if (element.requestFullScreen) {
    element.requestFullScreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.webkitEnterFullscreen) {
    element.webkitEnterFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

export const exitFullScreen = (element: any) => {
  if (element.exitFullscreen) {
    element.exitFullscreen();
  } else if (element.cancelFullScreen) {
    element.cancelFullScreen();
  } else if (element.mozCancelFullScreen) {
    element.mozCancelFullScreen();
  } else if (element.webkitCancelFullScreen) {
    element.webkitCancelFullScreen();
  } else if (element.msExitFullscreen) {
    element.msExitFullscreen();
  }
};

export const isPWA = () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }
  return false;
};
export const omitString = (value: string | undefined | null, length: number): string => {
  if (!value) {
    return "";
  }
  if (value.length <= length) {
    return value;
  }
  return value.slice(0, length) + "...";
};
