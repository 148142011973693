import type { PlanPrice } from "~/entities/PlanPrice";
import type { PlanPriceResponse } from "~/entities/PlanPriceResponse";
import type { IPlanPriceManageRepository } from "~/interfaces/IPlanPriceManageRepository";

export class PlanPriceManageRepository implements IPlanPriceManageRepository {
  async fetch(options: Record<string, any>): Promise<PlanPriceResponse> {
    const paramsObj: { [name: string]: string } = {
      ...filterEmptyObject(options || {}),
    };
    const searchParams = new URLSearchParams(paramsObj);
    const plans = await GetRequest<PlanPriceResponse>(`/plan_price_manage?${searchParams.toString()}`);
    return plans;
  }

  async fetchOne(planPriceId: string): Promise<{ planPrice: PlanPrice }> {
    const plans = await GetRequest<{ planPrice: PlanPrice }>(`/plan_price_manage?${planPriceId}`);
    return plans;
  }
}
