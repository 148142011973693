import AgoraRTC from "agora-rtc-sdk-ng";
import { type IDeviceRepository } from "~/interfaces/IDeviceRepository";

// agoraのログレベルを設定
// 0: DEBUG. Output all API logs.
// 1: INFO. Output logs of the INFO, WARNING and ERROR level.
// 2: WARNING. Output logs of the WARNING and ERROR level.
// 3: ERROR. Output logs of the ERROR level.
// 4: NONE. Do not output any log.
// 定数の定義が見つからないので数値を直接指定
AgoraRTC.setLogLevel(2);

/**
 * カメラ、マイクデバイス用リポジトリ
 */
export class DeviceRepository implements IDeviceRepository {
  /**
   * 接続されているカメラ一覧を取得する
   */
  async getCameras(): Promise<MediaDeviceInfo[]> {
    // カメラを取得する。ブラウザのパーミッション確認もこの段階で行われる
    return await AgoraRTC.getCameras(false);
  }

  /**
   * 接続されているマイク一覧を取得する
   */
  async getMicrophones(): Promise<MediaDeviceInfo[]> {
    // マイクを取得する。ブラウザのパーミッション確認もこの段階で行われる
    return await AgoraRTC.getMicrophones(false);
  }

  /**
   * デバイス変更時のコールバックを設定する
   * @param callback
   */
  setCallBack(callback: () => void | undefined): void {
    AgoraRTC.onCameraChanged = callback;
    AgoraRTC.onMicrophoneChanged = callback;
  }
}
