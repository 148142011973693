import type { ILoginRepository } from "~/interfaces/ILoginRepository";
import { LoginInfo } from "~/entities/LoginInfo";

export class LoginRepository implements ILoginRepository {
  async login(idToken: string): Promise<LoginInfo> {
    const body = {
      id_token: idToken,
    };
    return await PostRequest<LoginInfo>("/login", JSON.stringify(body), true, false, true);
  }

  async register(idToken: string): Promise<LoginInfo> {
    const body = {
      id_token: idToken,
    };
    return await PostRequest<LoginInfo>("/register", JSON.stringify(body), true, false, true);
  }

  async adminLogin(idToken: string): Promise<LoginInfo> {
    const body = {
      id_token: idToken,
    };
    return await PostRequest<LoginInfo>("/admin/login", JSON.stringify(body));
  }

  async refresh(refreshToken: string): Promise<LoginInfo> {
    const body = {
      refresh_token: refreshToken,
    };
    return await PostRequest<LoginInfo>("/refresh", JSON.stringify(body), false, true);
  }
}
