<script setup lang="ts">
  interface Props {
    iconImgPaths: string[];
    labels: string[];
    keys: string[];
    values: string[];
    disabled: boolean;
  }
  const props = defineProps<Props>();

  interface Emits {
    (event: "update:values", values: string[]): void;
  }
  const emit = defineEmits<Emits>();
  const computedValues = computed<string[]>({
    get: () => props.values,
    set: values => {
      emit("update:values", values);
    },
  });

  // レスポンシブ対応
  const isSp = isSpDisplayRef();
</script>

<template>
  <template v-for="(label, i) in labels" :key="keys[i]">
    <div class="d-flex row">
      <div class="flex-grow-0 my-auto">
        <AtomsSvgIcon :width="20" :img-path="iconImgPaths[i]" class="mr-2" />
      </div>
      <div class="flex-grow-1 my-auto">
        <div :class="isSp ? 'label-sp' : 'label'" :style="disabled ? 'color: #AAAAAA' : ''">
          {{ label }}
        </div>
      </div>
      <div class="flex-grow-0">
        <v-switch
          v-model="computedValues"
          color="primary"
          :value="keys[i]"
          inset
          class="switch"
          :disabled="disabled"
        ></v-switch>
      </div>
    </div>
    <v-divider :thickness="2"></v-divider>
  </template>
</template>

<style lang="scss" scoped>
  .row {
    height: 48px;
  }
  .label {
    font-size: 18px;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    text-align: left;
  }
  .label-sp {
    font-size: 14px;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    text-align: left;
  }
  .switch {
    margin-top: -4px;
    width: 60px;
  }
</style>
