import { doc, setDoc, onSnapshot, type Unsubscribe } from "firebase/firestore";
import type { ILiveStatusRepository, observerType } from "~/interfaces/ILiveStatusRepository";
import { LiveStatus, LiveStatusConverter, LiveStatusType } from "~/entities/LiveStatus";

/* firestoreのデータ構成
livestatuses(collection)
  - {liveId:LiveStatus}(document)
  - {liveId:LiveStatus}(document)
  - {liveId:LiveStatus}(document)
*/

const collectionName = "livestatuses";

export class LiveStatusRepository implements ILiveStatusRepository {
  observer: observerType | undefined = undefined;
  unsubscribe: Unsubscribe | undefined = undefined;

  getDocQuery(liveId: string) {
    const { $firestore } = useNuxtApp();
    return doc($firestore, collectionName, liveId).withConverter(LiveStatusConverter);
  }

  async fetch(liveId: string): Promise<LiveStatus> {
    const doc = this.getDocQuery(liveId);
    const snap = await getFirestoreDoc(doc);

    if (snap.exists()) {
      return snap.data();
    }
    return new LiveStatus(liveId, LiveStatusType.None, null);
  }

  async set(liveId: string, liveStatus: LiveStatus): Promise<void> {
    try {
      const doc = this.getDocQuery(liveId);
      await setDoc(doc, liveStatus);
    } catch (e) {
      throw new Error("Error set liveStatus: ", e);
    }
  }

  startObserve(liveId: string, observer: observerType): void {
    this.stopObserve();
    this.observer = observer;
    const doc = this.getDocQuery(liveId);
    this.unsubscribe = onSnapshot(doc, snapshot => {
      if (snapshot.exists()) {
        if (this.observer) {
          this.observer(snapshot.data());
        }
      }
    });
  }

  stopObserve() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = undefined;
    }
  }
}
