import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin(nuxtApp => {
  const { vueApp: app } = nuxtApp;
  const appUrl = window.location.origin;
  const router = useRouter();

  // スパイクするエラーログを送信するレート。環境によって調整
  const sampleRateForSpikingErrors = nuxtApp.$config.public.appEnv === "production" ? 0.01 : 1;

  Sentry.init({
    app,
    dsn: nuxtApp.$config.public.sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", appUrl],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        // https://fincs.backlog.com/view/FINCS-1730 デバッグ用
        networkDetailAllowUrls: ["https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen/channel"],
        networkRequestHeaders: ["X-Custom-Header"],
        networkResponseHeaders: ["X-Custom-Header"],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 1% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // 送信前にエラーをフィルタリングする
    beforeSend(error, hint) {
      return canSendErrorLog(error, hint, sampleRateForSpikingErrors) ? error : null;
    },
  });

  return {
    provide: {
      sentry: Sentry,
    },
  };
});

const canSendErrorLog = (error: Sentry.Event, _hint: Sentry.EventHint, sampleRateForSpikingErrors: number) => {
  // exception を抽出。なければ送信OKとする
  const exceptions = error.exception?.values;
  if (!exceptions || "at" in exceptions === false) {
    return true;
  }
  const exception = exceptions.at(0);
  if (!exception) {
    return true;
  }

  // https://fincs.backlog.com/view/FINCS-1729 対策
  // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver のエラーを無視する。
  // 描画しきれなかったイベントを通知する仕組みだが、無視しても実害はなく、エラーログが埋もれるので無視する。
  if (
    exception.type === "Error" &&
    exception.value === "ResizeObserver loop completed with undelivered notifications."
  ) {
    console.log("[Skip sending sentry error] ResizeObserver loop completed with undelivered notifications.");
    return false;
  }

  // https://fincs.backlog.com/view/FINCS-1730 対策
  // TypeError: Load failed のエラーログを軽減
  if (exception.type === "TypeError" && exception.value === "Load failed") {
    // TODO sentry のログより breadcrumb に fetch firestore listen channel が含まれるケースで多発しているのでそこに絞りたいが、
    // breadcrumb をテストしきれていないのでひとまずすべてに適用する
    if (Math.random() > sampleRateForSpikingErrors) {
      return false;
    }
  }

  // https://fincs.backlog.com/view/FINCS-1764 対策
  // Error: Rendered items limit reached のエラーログを軽減
  if (exception.type === "Error" && exception.value === "Rendered items limit reached") {
    if (Math.random() > sampleRateForSpikingErrors) {
      return false;
    }
  }

  // https://fincs.backlog.com/view/FINCS-2767 対策
  // Firestore 関連 Unhandled Error のエラーログを軽減
  if (
    exception.type === "Error" &&
    (exception.value === "UnknownError: Connection to Indexed Database server lost. Refresh the page to try again" ||
      exception.value === "FIRESTORE (10.13.1) INTERNAL ASSERTION FAILED: Unexpected state")
  ) {
    if (Math.random() > sampleRateForSpikingErrors) {
      return false;
    }
  }

  return true;
};
