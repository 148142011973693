import { defineStore } from "pinia";

/**
 * agoraの情報をもつストア
 * composable/useAgoraと統一して全てStoreにしたかったが、
 * piniaに入れるとagoraEngineが初期化できず、配信ページを出て再度配信ページに入った際にカメラの制御ができなくなる。
 * そのためロジックとデータを分けている
 */
export const useAgoraStore = defineStore("AgoreStore", () => {
  // リモートユーザー(配信者)のagoraUID。
  const remoteUid = ref<string | null>(null);
  // 映像のミュート状態(配信者)
  const isVideoMuted = ref<boolean>(false);
  // 音声のミュート状態(配信者)
  const isAudioMuted = ref<boolean>(false);
  // 音声のミュート状態(視聴者)
  const isLocalAudioMuted = ref<boolean>(false);

  // 接続状態
  const isJoined = ref<boolean>(false);
  // ネットワークの状態 6段階 0:なし 1,2:良い 他:悪い
  const networkQuality = ref<number>(0);

  // 配信終了ボタンが押されたかどうか。ヘッダーに配信終了ボタンがあるのでstoreを介してやりとりする
  const onLeaveButton = ref<boolean>(false);

  const reset = (): void => {
    remoteUid.value = null;
    isVideoMuted.value = false;
    isAudioMuted.value = false;
    isLocalAudioMuted.value = false;
    isJoined.value = false;
    networkQuality.value = 0;
    onLeaveButton.value = false;
  };

  const setOnLeaveButton = (value: boolean) => {
    onLeaveButton.value = value;
  };

  return {
    remoteUid,
    isVideoMuted,
    isAudioMuted,
    isLocalAudioMuted,
    isJoined,
    networkQuality,
    onLeaveButton,
    reset,
    setOnLeaveButton,
  };
});
