import type { IDistributionRepository } from "~/interfaces/IDistributionRepository";
import { DistributionInfo, type DistributionType } from "~/entities/DistributionInfo";

export class DistributionRepository implements IDistributionRepository {
  /**
   * 配信用のコンテンツファイルをアップロードする
   */
  async uploadContentFile(
    distributionInfo: DistributionInfo,
    file: File,
  ): Promise<{ fileKey: string }> {
    return await this.uploadFile(`/contents_manage/contents/presigned_url`, file);
  }

  /**
   * 配信用のサムネイルファイルをアップロードする
   */
  async uploadThumbnailFile(
    _distributionInfo: DistributionInfo,
    file: File,
  ): Promise<{ fileKey: string }> {
    return await this.uploadFile(`/contents_manage/thumbnail/presigned_url`, file);
  }

  /**
   * 配信コンテンツを投稿する
   */
  async postDistribution(distributionInfo: DistributionInfo): Promise<{ contentsId: string }> {
    const contentType = this.getContentType(distributionInfo.type);

    const distributeAt =
      distributionInfo.type === "live" && !distributionInfo.distributeNow
        ? distributionInfo.distributeAt
        : null;

    const res = await PostRequest<{ statusCode: number; contentsId: string }>(
      `/contents_manage`,
      JSON.stringify({
        contents_type: contentType,
        plan_id: distributionInfo.planId,
        is_free: distributionInfo.isFree,
        title: distributionInfo.title,
        description: distributionInfo.detail,
        contents_key: distributionInfo.contentFile.fileKey,
        thumbnail_key: distributionInfo.thumbnailFile.fileKey,
        tags: distributionInfo.tags.map(tag => Number(tag.id)),
        library_ids: distributionInfo.libraries.map(library => library.libraryId),
        start_date: distributeAt ? this.convertDateString(distributeAt) : null, // 今すぐ配信の場合はnullを入れる
        movie_play_time_sec: distributionInfo.moviePlayTimeSec,
        publish_status: distributionInfo.publishStatus,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to post distribution");
    }
    return { contentsId: res.contentsId };
  }

  /**
   * 配信コンテンツを変更する
   */
  async putDistribution(distributionInfo: DistributionInfo): Promise<void> {
    const contentType = this.getContentType(distributionInfo.type);
    const thumbnailKey = distributionInfo.thumbnailFile.fileKey.includes("http")
      ? distributionInfo.thumbnailKey
      : distributionInfo.thumbnailFile.fileKey;
    const distributeAt =
      distributionInfo.type === "live" && !distributionInfo.distributeNow
        ? distributionInfo.distributeAt
        : null;
    const res = await PutRequest<{ statusCode: number }>(
      `/contents_manage/${distributionInfo.contentsId}`,
      JSON.stringify({
        contents_type: contentType,
        plan_id: distributionInfo.planId,
        is_free: distributionInfo.isFree,
        title: distributionInfo.title,
        description: distributionInfo.detail,
        contents_key: distributionInfo.contentFile.fileKey,
        thumbnail_key: thumbnailKey,
        tags: distributionInfo.tags.map(tag => Number(tag.id)),
        library_ids: distributionInfo.libraries.map(library => library.libraryId),
        start_date: distributeAt ? this.convertDateString(distributeAt) : null,
        publish_status: distributionInfo.publishStatus,
      }),
      true,
      true,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to put distribution");
    }
  }

  /**
   * 配信コンテンツを削除する
   */
  async deleteDistribution(distributionInfo: DistributionInfo): Promise<void> {
    const res = await DeleteRequest<{ statusCode: number; contentsId: string }>(
      `/contents_manage/${distributionInfo.contentsId}`,
      true,
      true,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to delete distribution");
    }
  }

  private async uploadFile(apiPath: string, file: File) {
    // 署名付きS3アップロードURLを取得する
    const mimeType = file.type;
    const presignedRes = await GetRequest<{
      statusCode: number;
      presignedUrl: string;
      fileKey: string;
    }>(`${apiPath}?mime_type=${mimeType}`);
    if (!presignedRes || presignedRes.statusCode !== 200) {
      throw new Error("Failed get presigned_url");
    }

    // S3 にアップロード
    const s3Res = await PutS3Request(presignedRes.presignedUrl, file);
    if (!s3Res || s3Res.statusCode !== 200) {
      throw new Error("Failed to put s3");
    }

    const fileKey = presignedRes.fileKey;
    return { fileKey };
  }

  private getContentType(distributionType: DistributionType) {
    switch (distributionType) {
      case "live":
        return "schedule";
      case "livearchive":
        return "live_movie";
      case "movie":
        return "movie";
      case "document":
        return "document";
    }
  }

  private convertDateString(dateString: string) {
    return new Date(dateString).toISOString();
  }

  private nowDateString() {
    return new Date().toISOString();
  }
}
