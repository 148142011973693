import { defineStore } from "pinia";
import { useLoginStore } from "./LoginStore";
import type { ILogRepository } from "~/interfaces/ILogRepository";
import { ILogRepositoryFactoryKey, injectRepository } from "~/Repositories/provide";

/**
 * ログを扱うクラス
 */
export const useLogStore = defineStore(
  "LogStore",
  () => {
    const repository: ILogRepository = injectRepository(ILogRepositoryFactoryKey);

    const loginStore = useLoginStore();
    const sendedData = ref<{ user_id: string | null; device_id: string | null }>({ user_id: null, device_id: null });

    const PwaUsageLog = async () => {
      if (
        sendedData.value.user_id === loginStore.loginInfo?.userId &&
        sendedData.value.device_id === loginStore.deviceToken
      ) {
        // 前回と同じ内容であれば送信しない
        return;
      }
      const data = {
        log_type: "use_webapp",
        user_id: loginStore.loginInfo?.userId,
        device_id: loginStore.deviceToken,
      };
      try {
        await repository.PwaUsageLog(data);
        sendedData.value = { user_id: loginStore.loginInfo?.userId, device_id: loginStore.deviceToken };
      } catch (e) {
        sentryErrorLog(`use_webapp log error: ${e}`);
      }
    };

    return {
      PwaUsageLog,
      sendedData,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
