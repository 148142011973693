import { query, orderBy, where, collection, addDoc, onSnapshot, type Unsubscribe } from "firebase/firestore";
import type { ILiveChatRepository, observerType } from "~/interfaces/ILiveChatRepository";
import { LiveChatMessage, LiveChatMessageConverter } from "~/entities/LiveChatMessage";

// firestoreのデータ構成はサブコレクションを利用する

/* データ構成
livechats(collection)
  - {liveId}(document)
    - messages(collection)
      - {LiveChatMessage}(document)
      - {LiveChatMessage}(document)
      - {LiveChatMessage}(document)
  - {liveId}(document)
    - messages(collection)
      - {LiveChatMessage}(document)
      - {LiveChatMessage}(document)
      - {LiveChatMessage}(document)
  - {liveId}(document)
    - messages(collection)
      - {LiveChatMessage}(document)
      - {LiveChatMessage}(document)
      - {LiveChatMessage}(document)
  - {liveId}(document)
    - messages(collection)
      - {LiveChatMessage}(document)
      - {LiveChatMessage}(document)
      - {LiveChatMessage}(document)
*/

const collectionName = "livechats";
const subCollectionName = "messages";

export class LiveChatRepository implements ILiveChatRepository {
  observer: observerType | undefined = undefined;
  unsubscribe: Unsubscribe | undefined = undefined;
  // fetchしたデータの最新のDateを保存しておく。リアルタイムデータ取得時にこのDate以降のデータを取得するするため。
  fetchLastDate: Date | null = null;

  getCollection(liveId: string) {
    const { $firestore } = useNuxtApp();
    return collection($firestore, collectionName, liveId, subCollectionName).withConverter(LiveChatMessageConverter);
  }

  async fetch(liveId: string): Promise<LiveChatMessage[]> {
    const collection = this.getCollection(liveId);
    const q = query(collection, orderBy("createdAt", "asc"));
    const querySnapshot = await getFirestoreDocs(q);

    const list: LiveChatMessage[] = [];
    querySnapshot.forEach(doc => {
      list.push(doc.data());
      this.fetchLastDate = doc.data().createdAt;
    });
    if (this.fetchLastDate === null) {
      // データがない場合は現在のDateを入れる
      this.fetchLastDate = new Date();
    }
    return list;
  }

  async insert(liveId: string, liveChatMessage: LiveChatMessage): Promise<void> {
    const collection = this.getCollection(liveId);
    await addDoc(collection, liveChatMessage);
  }

  startObserve(liveId: string, observer: observerType): void {
    this.stopObserve();
    this.observer = observer;
    const collection = this.getCollection(liveId);
    const q = query(collection, where("createdAt", ">", this.fetchLastDate));
    this.unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        // 追加時
        if (change.type === "added") {
          if (this.observer) {
            this.observer(change.doc.data());
          }
        }
      });
    });
  }

  stopObserve() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = undefined;
    }
  }
}
