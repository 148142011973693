export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.config.errorHandler = (error, _instance, _info) => {
    reloadOnFirebaseError(error);
    reloadOnIndexDBError(error);
  };
});

const reloadOnIndexDBError = (error: any) => {
  // https://arkad-nm.sentry.io/issues/4873605465/?project=4506294992764928&referrer=issue-stream&sort=os&statsPeriod=3d&stream_index=4
  if ("name" in error && error.name === "Error") {
    if (
      error.message.includes("UnknownError: Connection to Indexed Database server lost. Refresh the page to try again")
    ) {
      sentryWarnLog(`reloadOnIndexDBError: do reload: ${error}`);
      reloadNuxtApp();
    }
  }
};
