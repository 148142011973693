export class MessageInfo {
  public title: string;
  public description: string;

  constructor(title = "", description = "") {
    this.title = title;
    this.description = description;
  }
}

export class LoginInfo {
  public statusCode: number;
  public userId: string;
  public userRoleCode: string;
  public userRoleName: string;
  public sessionToken: string;
  public refreshToken: string;
  public email: string;
  public fullName: string;
  public fullNameKana: string;
  public userImage: string;
  public userName: string;
  public message: MessageInfo;

  constructor(
    statusCode = 0,
    userId = "",
    userRoleCode = "",
    userRoleName = "",
    sessionToken = "",
    refreshToken = "",
    email = "",
    fullName = "",
    fullNameKana = "",
    userImage = "",
    userName = "",
    message: MessageInfo = new MessageInfo(),
  ) {
    this.statusCode = statusCode;
    this.userId = userId;
    this.userRoleCode = userRoleCode;
    this.userRoleName = userRoleName;
    this.sessionToken = sessionToken;
    this.refreshToken = refreshToken;
    this.email = email;
    this.fullName = fullName;
    this.fullNameKana = fullNameKana;
    this.userImage = userImage;
    this.userName = userName;
    this.message = message;
  }
}
