<script setup lang="ts">
  interface Props {
    type?: "button" | "submit" | "reset";
    color?:
      | "blue"
      | "monochrome-02"
      | "monochrome-06"
      | "component"
      | "white"
      | "red"
      | "error"
      | "main-04"
      | "primary";
    styleBgColor?: string;
    size?: "large" | "x-large";
    rounded?: string;
    variant?: "outlined" | undefined;
    width?: number;
    height?: number;
    href?: string;
    disabled?: boolean;
    loading?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    type: "button",
    color: "blue",
    styleBgColor: undefined,
    size: "large",
    rounded: "4",
    variant: undefined,
    width: undefined,
    height: undefined,
    href: undefined,
    disabled: false,
  });

  // 親の div に width を指定可能とすることで、
  // v-btn の min-width: 100% を上書きできるようにする
  // (heightはそのまま渡せばOK)
  const wrapperWidth = computed<string>(() => (props.width ? `${props.width}px` : "100%"));
  const styleBgColor = props.styleBgColor;
</script>

<template>
  <div class="button-wrapper">
    <v-btn
      :type="type"
      class="v-btn"
      :class="styleBgColor ? 'v-btn-bg' : ''"
      block
      :rounded="rounded"
      :size="size"
      :height="height"
      :color="color"
      :variant="variant"
      :href="href"
      :disabled="disabled"
      :loading="loading"
    >
      <slot />
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
  .button-wrapper {
    width: v-bind(wrapperWidth);
  }
  .v-btn-bg {
    background: v-bind(styleBgColor) !important;
  }
  .v-btn[disabled] {
    opacity: 0.6;
  }
</style>
