import { defineStore } from "pinia";
import { useContentsStore } from "./ContentsStore";
import { LiveInfo } from "~/entities/LiveInfo";
import { Lives } from "~/entities/Lives";

export const useLiveArchiveStore = defineStore("LiveArchiveStore", () => {
  const contentStore = useContentsStore();

  const liveArchive = ref<LiveInfo>();
  const liveArchives = ref<Lives>();
  const loading = ref(false);
  const loadingList = ref(false);

  const fetchOne = async (contentsId: string) => {
    loading.value = true;
    liveArchive.value = undefined;
    try {
      liveArchive.value = await contentStore.getDetail<LiveInfo>(contentsId);
    } finally {
      loading.value = false;
    }
  };

  const fetch = async (
    page: number,
    pageSize: number,
    planId: string | undefined = undefined,
    options?: Record<string, any>,
  ) => {
    loadingList.value = true;
    liveArchives.value = undefined;
    try {
      liveArchives.value = await contentStore.getList<Lives>(page, pageSize, {
        contents_types: ["archive_movie"],
        plan_id: planId,
        ...options,
      });
    } finally {
      loadingList.value = false;
    }
  };

  return {
    liveArchive,
    liveArchives,
    loading,
    loadingList,
    fetchOne,
    fetch,
  };
});
