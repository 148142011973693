export class UserContentsInfo {
  constructor(
    public id: string, // document ID
    public read: boolean, // 既読フラグ
    public currentTime: number, // 前回視聴位置
    public maxTime: number, // 視聴位置の最大
    public maxPage: number, // 閲覧ページの最大
  ) {}

  public static empty(): UserContentsInfo {
    return new UserContentsInfo("", false, 0, 0, 0);
  }
}

// Firestore data converter
export const UserContentsInfoConverter = {
  toFirestore: (item: any) => {
    return {
      read: item.read,
      currentTime: item.currentTime,
      maxTime: item.maxTime,
      maxPage: item.maxPage,
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new UserContentsInfo(
      snapshot.id,
      !!data.read,
      data.currentTime ?? 0,
      data.maxTime ?? 0,
      data.maxPage ?? 0,
    );
  },
};
