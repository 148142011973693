/**
 * nuxtが動的なsrcを解決できないため、それに対応するための関数
 * @param {string} imgPath - assets/images/ からはじまる画像のパス
 */
export const generateImgPath = (imgPath: string): string => {
  const assets = import.meta.glob("~/assets/images/**/*", {
    eager: true,
    import: "default",
  });
  // @ts-expect-error: wrong type info
  return assets["/" + imgPath];
};
