import { NotificationSettingItem, NotificationSettings } from "~/entities/NotificationSetting";
import type { INotificationSettingRepository } from "~/interfaces/INotificationSettingRepository";

export class NotificationSettingRepository implements INotificationSettingRepository {
  async getSettings(planId: string): Promise<NotificationSettings> {
    const res = await GetRequest<{ statusCode: number; webPushSettings: any; lineSettings: any }>(
      `/notification_setting/${planId}`,
      false,
      true,
    );
    return new NotificationSettings(res.webPushSettings, res.lineSettings);
  }

  async putSettings(planId: string, settings: NotificationSettings): Promise<void> {
    const convertSetting = (setting: NotificationSettingItem) => {
      const convertNotificationType = (notificationType: string) => {
        if (notificationType === "webpush") {
          return "web_push_notification";
        } else if (notificationType === "line") {
          return "line_notification";
        } else {
          throw new Error("Invalid notificationType");
        }
      };
      if (setting.contentsType === "talk_room") {
        return {
          notification_type: convertNotificationType(setting.notificationType),
          contents_type: setting.contentsType,
          talk_room_id: setting.roomId,
        };
      } else {
        return {
          notification_type: convertNotificationType(setting.notificationType),
          contents_type: setting.contentsType,
        };
      }
    };
    const webPushSettingsToPut = settings.webpushSettings.map(setting => convertSetting(setting));
    const lineSettingsToPut = settings.lineSettings.map(setting => convertSetting(setting));
    const settingsToPut = {
      settings: webPushSettingsToPut.concat(lineSettingsToPut),
    };
    await PutRequest<{ statusCode: number }>(
      `/notification_setting/${planId}`,
      JSON.stringify(settingsToPut),
      true,
      true,
    );
  }
}
