export class DistributionFile {
  public name: string; // ファイル名
  public previewImgSrc: string; // プレビュー画像src (base64データ or URL)
  public fileKey: string; // アップロード先key

  constructor(name = "", previewImgSrc = "", fileKey = "") {
    this.name = name;
    this.previewImgSrc = previewImgSrc;
    this.fileKey = fileKey;
  }
}
