import snakecaseKeys from "snakecase-keys";
import { TalkRoomEditInfo } from "~/entities/TalkRoomEditInfo";
import { TalkRoomDisplayOrder } from "~/entities/TalkRoomDisplayOrder";
import type { ITalkRoomEditRepository } from "~/interfaces/ITalkRoomEditRepository";

export class TalkRoomEditRepository implements ITalkRoomEditRepository {
  async createTalkRoom(planId: string, talkRoomEditInfo: TalkRoomEditInfo): Promise<string> {
    const res = await PostRequest<{ statusCode: number; talkRoomId: string }>(
      `/talk/room`,
      JSON.stringify({
        room_name: talkRoomEditInfo.name,
        room_description: talkRoomEditInfo.description,
        is_only_owner_post: !talkRoomEditInfo.canUserPost,
        is_free_flag: !talkRoomEditInfo.isLimited,
        published_flag: talkRoomEditInfo.isPublished,
        is_line_notify: talkRoomEditInfo.defaultNotification,
        display_order: talkRoomEditInfo.displayOrder,
        owner_plan_id: planId,
        dm_user_id: talkRoomEditInfo.dmUserId,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("TalkRoomEditRepository.createTalkRoom API Error");
    }
    return res.talkRoomId;
  }

  async updateTalkRoom(talkRoomId: string, talkRoomEditInfo: TalkRoomEditInfo): Promise<void> {
    const res = await PutRequest<{ statusCode: number }>(
      `/talk/room/${talkRoomId}`,
      JSON.stringify({
        room_name: talkRoomEditInfo.name,
        room_description: talkRoomEditInfo.description,
        is_only_owner_post: !talkRoomEditInfo.canUserPost,
        is_free_flag: !talkRoomEditInfo.isLimited,
        published_flag: talkRoomEditInfo.isPublished,
        is_line_notify: talkRoomEditInfo.defaultNotification,
        is_archive: talkRoomEditInfo.isArchive,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("TalkRoomEditRepository.updateTalkRoom API Error");
    }
  }

  async deleteTalkRoom(talkRoomId: string): Promise<void> {
    const res = await DeleteRequest<{ statusCode: number }>(`/talk/room/${talkRoomId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("TalkRoomEditRepository.deleteTalkRoom API Error");
    }
  }

  async setDisplayOrders(displayOrders: TalkRoomDisplayOrder[]): Promise<void> {
    const res = await PutRequest<{ statusCode: number }>(
      `/talk/room/display_order`,
      JSON.stringify(snakecaseKeys({ displayOrders })),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("TalkRoomEditRepository.setDisplayOrders API Error");
    }
  }
}
