import type { IPaymentHistoryRepository } from "~/interfaces/IPaymentHistoryRepository";
import { PaymentHistory } from "~/entities/PaymentHistory";

export class PaymentHistoryRepository implements IPaymentHistoryRepository {
  async fetch(page: number, pageSize: number): Promise<any> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: pageSize.toString(),
    };
    const searchParams = new URLSearchParams(paramsObj);

    const result = await GetRequest<{
      statusCode: number;
      page: number;
      totalPages: number;
      paymentHistories: PaymentHistory[];
    }>(`/payment/history?` + searchParams.toString());
    return {
      page: result.page,
      totalPages: result.totalPages,
      paymentHistories: result.paymentHistories,
    };
  }

  async fetchLatestOne(planId: string): Promise<any> {
    const paramsObj: { [name: string]: string } = {
      page: "1",
      page_size: "1",
      plan_id: planId,
      sort: "desc",
    };
    const searchParams = new URLSearchParams(paramsObj);

    const result = await GetRequest<{
      statusCode: number;
      page: number;
      totalPages: number;
      paymentHistories: PaymentHistory[];
    }>(`/payment/history?` + searchParams.toString());
    return {
      page: result?.page,
      totalPages: result?.totalPages,
      paymentHistories: result?.paymentHistories,
    };
  }

  /**
   *
   * @param paymentHistoryId
   * @param body {email: string; name: string;}
   * @returns
   */
  async generateReceipt(paymentHistoryId: string, body: Record<string, string>): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(`/payment/receipt/${paymentHistoryId}`, JSON.stringify(body));
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to generate receipt");
    }
  }
}
