import { defineStore } from "pinia";
import { useContentsStore } from "./ContentsStore";
import { DocumentInfo } from "~/entities/DocumentInfo";
import { Documents } from "~/entities/Documents";

export const useDocumentStore = defineStore("DocumentStore", () => {
  const contentStore = useContentsStore();

  const documents = ref<Documents>();
  const planDocuments = ref<Documents>(); // コンテンツ閲覧画面下部に表示するデータ
  const document = ref<DocumentInfo>();
  const loading = ref(false);

  const fetchOne = async (contentsId: string) => {
    loading.value = true;
    document.value = undefined;
    try {
      document.value = await contentStore.getDetail<DocumentInfo>(contentsId);
    } finally {
      loading.value = false;
    }
  };

  // optionsにis_free, search_wordが入ります。
  const fetch = async (
    page: number,
    pageSize: number,
    planId: string | undefined,
    options?: Record<string, any>,
  ) => {
    loading.value = true;
    documents.value = undefined;
    try {
      documents.value = await contentStore.getList<Documents>(page, pageSize, {
        contents_types: ["document"],
        plan_id: planId,
        ...options,
      });
    } finally {
      loading.value = false;
    }
  };

  // コンテンツ閲覧画面下部に表示するデータを取得する
  const fetchPlanDocuments = async (
    page: number,
    pageSize: number,
    planId: string | undefined,
    exclusionContentsId: string | undefined,
  ) => {
    loading.value = true;
    planDocuments.value = undefined;
    try {
      planDocuments.value = await contentStore.getList<Documents>(page, pageSize, {
        contents_types: ["document"],
        plan_id: planId,
        exclusion_contents_id: exclusionContentsId,
      });
    } finally {
      loading.value = false;
    }
  };

  return {
    document,
    documents,
    planDocuments,
    loading,
    fetchOne,
    fetch,
    fetchPlanDocuments,
  };
});
