import { type IHttpResponse } from "~/interfaces/IHttpResponse";
import { type IPlanPriceRepository } from "~/interfaces/IPlanPriceRepository";
import { type IPlanPriceHistory, type IPlanPriceListResponse } from "~/entities/PlanPriceHistory";

export class PlanPriceRepository implements IPlanPriceRepository {
  async fetch(planId: string, page: number, pageSize: number): Promise<IPlanPriceListResponse> {
    const paramsObj: { [name: string]: string } = {
      plan_id: planId,
      page: page.toString(),
      page_size: pageSize.toString(),
    };
    const searchParams = new URLSearchParams(paramsObj);
    const res = await GetRequest<IPlanPriceListResponse>(`/plan_price_manage?` + searchParams.toString());
    if (!res || res.statusCode !== 200) {
      throw res;
    }
    return res;
  }

  async create(planId: string, planPrice: IPlanPriceHistory): Promise<void> {
    const jsonData: any = {
      plan_id: planId,
      plan_name: planPrice.planPriceName,
      is_subscription: planPrice.billingType === "contract_period",
      price: planPrice.price,
      start_date: planPrice.startDate,
      is_hidden: !planPrice.displayFlag,
    };
    if (planPrice.trialDays !== 0) {
      jsonData.trial_days = planPrice.trialDays;
    }
    if (planPrice.billingType === "contract_period") {
      jsonData.contract_period = planPrice.contractPeriod;
    } else if (planPrice.availableDays !== 0) {
      jsonData.available_days = planPrice.availableDays;
    }
    if (planPrice.endDate) {
      jsonData.end_date = planPrice.endDate;
    }

    const jsonString = JSON.stringify(jsonData);

    const res = await PostRequest<IHttpResponse>(`/plan_price_manage`, jsonString, true);

    if (!res || res.statusCode !== 200) {
      throw res;
    }
  }

  async update(planPrice: IPlanPriceHistory): Promise<void> {
    const jsonData: any = {
      plan_id: planPrice.planId,
      plan_name: planPrice.planPriceName,
      is_subscription: planPrice.billingType === "contract_period",
      price: planPrice.price,
      start_date: planPrice.startDate,
      is_hidden: !planPrice.displayFlag,
    };

    if (planPrice.trialDays !== 0) {
      jsonData.trial_days = planPrice.trialDays;
    }
    if (planPrice.billingType === "contract_period") {
      jsonData.contract_period = planPrice.contractPeriod;
    } else if (planPrice.availableDays !== 0) {
      jsonData.available_days = planPrice.availableDays;
    }
    if (planPrice.endDate) {
      jsonData.end_date = planPrice.endDate;
    }

    const jsonString = JSON.stringify(jsonData);

    const res = await PutRequest<IHttpResponse>(`/plan_price_manage/${planPrice.planPriceId}`, jsonString, true);

    if (!res || res.statusCode !== 200) {
      throw res;
    }
  }

  async deletePrice(planPriceId: string): Promise<void> {
    const res = await DeleteRequest<IHttpResponse>(`/plan_price_manage/${planPriceId}`, true);

    if (!res || res.statusCode !== 200) {
      throw res;
    }
  }

  async fetchChangeHistory(planPriceId: string, page: number, pageSize: number): Promise<IPlanPriceListResponse> {
    const paramsObj: { [name: string]: string } = {
      page: String(page),
      page_size: String(pageSize),
    };
    const searchParams = new URLSearchParams(paramsObj);
    const res = await GetRequest<IPlanPriceListResponse>(
      `/plan_price_manage/history/${planPriceId}?` + searchParams.toString(),
    );

    if (!res || res.statusCode !== 200) {
      throw res;
    }
    return res;
  }

  async setDisplayOrders(planPrices: IPlanPriceHistory[]): Promise<void> {
    const jsonData: any = {
      sorts: planPrices.map(planPrice => {
        return {
          plan_price_id: planPrice.planPriceId,
        };
      }),
    };

    const jsonString = JSON.stringify(jsonData);

    const res = await PutRequest<IHttpResponse>(`/plan_price_manage/sort`, jsonString, true);

    if (!res || res.statusCode !== 200) {
      throw res;
    }
  }
}
