/* eslint-disable import/no-duplicates */
import { format, parse } from "date-fns";
import ja from "date-fns/locale/ja";

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.config.globalProperties.$filters = {
    numberFormat(num: Number) {
      return (num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
    },
    moneyFormat(num: Number) {
      return (
        "￥" + (num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
      );
    },
    dateToYYYYMMDDWithLocale(date: string) {
      if (!date) return "";
      return format(parse(date, "yyyy-MM-dd", new Date()), "yyyy年MM月dd日", { locale: ja });
    },
  };
});
