<script setup lang="ts">
  interface Props {
    width: number;
    marginX?: number;
    imgPath: string;
  }
  const props = withDefaults(defineProps<Props>(), {
    width: 22,
    marginX: 0,
    imgPath: "assets/images/appIcon.svg",
  });

  // https://fincs.backlog.com/view/FINCS-1074 にてiPadで解像度が低い指摘があったため対策。
  // https://qiita.com/chrysalis/items/ab190bf6d1257610789a
  // を参考に後述の template 内 img の width を2倍にしてscale(0.5)する。
  // はみ出し分は style で調整。
  const containerWidth = props.width + props.marginX + "px";
  const containerHeight = props.width + "px";
</script>

<template>
  <div class="icon-container">
    <img :width="width * 2" class="icon-img" :src="generateImgPath(imgPath)" alt="icon-img" />
  </div>
</template>

<style lang="scss" scoped>
  .icon-container {
    width: v-bind("containerWidth");
    height: v-bind("containerHeight");
    overflow: hidden;
  }

  .icon-img {
    transform: scale(0.5) translate(-50%, -50%);
  }
</style>
