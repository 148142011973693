export const reloadOnFirebaseError = (error: any) => {
  if ("name" in error && error.name === "FirebaseError") {
    // まずは client が既に終了している場合に限定
    if (error.message.includes("The client has already been terminated.")) {
      sentryWarnLog(`reloadOnFirebaseError: do reload: ${error}`);
      reloadNuxtApp();
    } else {
      sentryErrorLog(`reloadOnFirebaseError: skip reload: ${error}`);
    }
  }
};
