// storybookでもimportするのでvuetify.tsではなく独立した定義とする
export const fincsTheme = {
  dark: false,
  colors: {
    primary: "#119CF6",
    secondary: "#161616",
    background: "#010101",
    surface: "#010101",
    component: "#161616",
    "live-timer": "#361EFF",
    "line-link": "#06C755",
    info: "#CB2E31",
    success: "#CB2E31",
    warning: "#CB2E31",
    error: "#CB2E31",

    "main-01": "#119CF6", // メインボタン
    "main-02": "#361EFF",
    "main-03": "#11F4ED",
    "main-04": "#CB2E31", // 警告系
    "monochrome-01": "#FFFFFF",
    "monochrome-02": "#AAAAAA", // ネガティブボタン
    "monochrome-03": "#666666",
    "monochrome-04": "#222222", // ダイアログ
    "monochrome-05": "#161616", // カード背景
    "monochrome-06": "#333333", // タグ背景
    "monochrome-07": "#383838", // 見出しボーダー
    "monochrome-08": "#191919", // 見出しボーダー

    text: "#FFFFFF",
    "text-gray": "#CCCCCC",
  },
};
