import {
  type IPlanPriceChangeReservationRepository,
  type IPlanPriceChangeReservationResponse,
} from "~/interfaces/IPlanPriceChangeReservationRepository";

export class PlanPriceChangeReservationRepository implements IPlanPriceChangeReservationRepository {
  async fetch(planId: string): Promise<IPlanPriceChangeReservationResponse> {
    const res = await GetRequest<IPlanPriceChangeReservationResponse>(`/plan_price_change_reservation/${planId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error(`PlanPriceChangeReservationRepository.get API Error: ${JSON.stringify(res)}`);
    }
    return res;
  }

  async set(planPriceId: string, sbPaymentId: number | null): Promise<IPlanPriceChangeReservationResponse> {
    let url = `/plan_price_change_reservation/${planPriceId}`;
    if (sbPaymentId !== null) {
      url += `/${sbPaymentId}`;
    }
    const res = await PostRequest<IPlanPriceChangeReservationResponse>(url);
    if (!res || res.statusCode !== 200) {
      throw new Error(`PlanPriceChangeReservationRepository.set API Error: ${JSON.stringify(res)}`);
    }
    return res;
  }

  async cancel(planPriceId: string): Promise<IPlanPriceChangeReservationResponse> {
    const res = await DeleteRequest<IPlanPriceChangeReservationResponse>(
      `/plan_price_change_reservation/${planPriceId}`,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`PlanPriceChangeReservationRepository.cancel API Error: ${JSON.stringify(res)}`);
    }
    return res;
  }
}
