<script setup lang="ts">
  const enablePwa = useRuntimeConfig().public.ENABLE_PWA === "true";
</script>

<template>
  <NuxtPwaManifest v-if="enablePwa" />
  <div>
    <NuxtPage />
  </div>
</template>
