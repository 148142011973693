import { defineStore } from "pinia";
import { type ILineLinkStatusRepository } from "~/interfaces/ILineLinkStatusRepository";
import { LineLinkStatus, LineLinkStatusType } from "~/entities/LineLinkStatus";
import { ILineLinkStatusRepositoryFactoryKey, injectRepository } from "~/Repositories/provide";

export const useLineLinkStatusStore = defineStore("LineLinkStatusStore", () => {
  const lineLinkStatus = ref<LineLinkStatus>();
  const loading = ref(false);

  const repository: ILineLinkStatusRepository = injectRepository(
    ILineLinkStatusRepositoryFactoryKey,
  );

  const set = async (
    userId: string,
    planId: string,
    status: LineLinkStatusType,
    error: string,
    errorDescription: string,
  ): Promise<boolean> => {
    let result = false;
    loading.value = true;
    try {
      await repository.set(userId, planId, status, error, errorDescription);
      result = true;
    } catch (e) {
      console.log(e);
    } finally {
      loading.value = false;
    }
    return result;
  };

  const startObserve = (userId: string) => {
    repository.startObserve(userId, (status: LineLinkStatus) => {
      lineLinkStatus.value = status;
    });
  };

  const stopObserve = () => {
    repository.stopObserve();
    reset();
  };

  const reset = () => {
    lineLinkStatus.value = undefined;
  };

  return {
    lineLinkStatus,
    loading,
    fetch,
    set,
    startObserve,
    stopObserve,
  };
});
