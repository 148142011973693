export type PaymentBrand = "V" | "M" | "J" | "A" | "D" | "X"; // visa, master, jcb, amex, diners, unknown

export class Payment {
  public id?: number;
  public brand: PaymentBrand;
  public cardNumber: string;
  public expireMonth: string;
  public expireYear: string;
  public code: string;
  public active: boolean;
  public invalid: boolean;

  constructor(
    id: number | undefined = undefined,
    brand: PaymentBrand = "X",
    cardNumber = "",
    expireMonth = "",
    expireYear = "",
    code = "",
    active = false,
    invalid = false,
  ) {
    this.id = id;
    this.brand = brand;
    this.cardNumber = cardNumber;
    this.expireMonth = expireMonth;
    this.expireYear = expireYear;
    this.code = code;
    this.active = active;
    this.invalid = invalid;
  }

  public static canUpdate(payment: Payment): boolean {
    return (
      this.isValidCardNumber(payment) &&
      this.isValidExpireMonth(payment) &&
      this.isValidExpireYear(payment) &&
      this.isValidCode(payment)
    );
  }

  public static isValidCardNumber(payment: Payment): boolean {
    return payment.brand !== "X" && Payment.toBrand(payment.cardNumber) !== "X";
  }

  public static isValidExpireMonth(payment: Payment): boolean {
    return (
      payment.expireMonth.length === 2 &&
      Number(payment.expireMonth) >= 1 &&
      Number(payment.expireMonth) <= 12
    );
  }

  public static isValidExpireYear(payment: Payment): boolean {
    return (
      payment.expireYear.length === 4 && Number(payment.expireYear) >= new Date().getFullYear()
    );
  }

  public static isValidCode(payment: Payment): boolean {
    // amexは4桁、それ以外は3桁
    return payment.brand === "A" ? /^\d{4}$/.test(payment.code) : /^\d{3}$/.test(payment.code);
  }

  public static toBrand(cardNumber: string): PaymentBrand {
    // ref: 判定ロジック https://www.robotpayment.co.jp/blog/creditcard/4194/
    if (/^4\d{15}$/.test(cardNumber)) {
      return "V";
    } else if (/^5\d{15}$/.test(cardNumber)) {
      return "M";
    } else if (/^35\d{14}$/.test(cardNumber)) {
      return "J";
    } else if (/^34\d{13}$/.test(cardNumber) || /^37\d{13}$/.test(cardNumber)) {
      return "A";
    } else if (/^36\d{12}$/.test(cardNumber)) {
      return "D";
    }
    return "X";
  }

  public static getBrand(payment: Payment): string | null {
    switch (payment.brand) {
      case "V":
        return "visa";
      case "M":
        return "master";
      case "J":
        return "jcb";
      case "A":
        return "amex";
      case "D":
        return "diners";
      case "X":
        return "unknown";
    }
  }

  public static getMaskedCardNumber(payment: Payment): string | null {
    switch (payment.brand) {
      case "V":
      case "M":
      case "J":
      case "X":
        return "**** **** **** " + payment.cardNumber.substring(12);
      case "A":
        return "**** ****** *" + payment.cardNumber.substring(11);
      case "D":
        return "**** ****** " + payment.cardNumber.substring(10);
    }
  }
}

export class CreditCard {
  sbPaymentId = 0;
  cardBrandCode: PaymentBrand = "V";
  ccNumber = "";
  ccExpiration = "";
  isAvailableCard = false;
  isUsedCard = false;
}
