import { defineStore } from "pinia";
import { type PLAN_TAB_TYPE } from "~/consts/PlanTabMenuConfig";

export const usePlanTabStore = defineStore("PlanTabStore", () => {
  const DEFAULT_TAB = "overview";
  // 現在のタブ。set時に行う処理があるのでgetter/setterを使う
  const tab = ref<PLAN_TAB_TYPE>("none");
  // 一度でも表示されたことがあるかどうか
  const showedTags = ref<string[]>([]);

  const resetTab = () => {
    tab.value = "none";
    showedTags.value = [];
  };

  const getTab = () => {
    return tab.value;
  };

  const setTab = (value: PLAN_TAB_TYPE) => {
    if (tab.value !== value) {
      tab.value = value;
      // showedTagsに追加する
      if (!showedTags.value.includes(value)) {
        showedTags.value.push(value);
      }
    }
  };

  const initTab = () => {
    setTab(DEFAULT_TAB);
  };

  // 一度でも表示されたことがあるかどうか
  const isShowed = (value: PLAN_TAB_TYPE) => {
    return showedTags.value.includes(value);
  };

  return {
    resetTab,
    getTab,
    setTab,
    initTab,
    isShowed,
  };
});
