import type { IPaymentRepository } from "~/interfaces/IPaymentRepository";
import { CreditCard, Payment } from "~/entities/Payment";
import type { TransferInfoResponse } from "~/entities/TransferInfoResponse";

// eslint-disable-next-line camelcase
declare const com_sbps_system: any;

export class PaymentRepository implements IPaymentRepository {
  async list(): Promise<Payment[]> {
    const res = await GetRequest<{ statusCode: number; creditCards: CreditCard[] }>(
      `/payment/credit-card`,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`PaymentRepository.list API Error: ${JSON.stringify(res)}`);
    }
    return res.creditCards.map((creditCard: CreditCard) => this.toPayment(creditCard));
  }

  async getByPlan(planId: string): Promise<Payment | undefined> {
    const res = await GetRequest<{ statusCode: number; creditCards: CreditCard[] }>(
      `/payment/credit-card?plan_id=${planId}`,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`PaymentRepository.getByPlan API Error: ${JSON.stringify(res)}`);
    }
    const creditCard = res.creditCards.find((creditCard: CreditCard) => creditCard.isUsedCard);
    return creditCard ? this.toPayment(creditCard) : undefined;
  }

  async add(payment: Payment): Promise<number> {
    const tokenResponse = await this.generateToken(payment);
    const res = await PostRequest<{ statusCode: number; sbPaymentId: number }>(
      `/payment/credit-card`,
      JSON.stringify({
        expire: this.toExpiration(payment),
        token: tokenResponse.token,
        token_key: tokenResponse.tokenKey,
      }),
      true, // 共通エラー処理のダイアログを表示しない
      false, // トークンリフレッシュではない
      true, // エラー時にthrowする
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`PaymentRepository.add API Error: ${JSON.stringify(res)}`);
    }
    return res.sbPaymentId;
  }

  async remove(id: number): Promise<void> {
    const res = await DeleteRequest<{ statusCode: number }>(
      `/payment/credit-card/${id}`,
      true, // 共通エラー処理のダイアログを表示しない
      true, // エラー時にthrowする
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`PaymentRepository.remove API Error: ${JSON.stringify(res)}`);
    }
  }

  async bankTransfer(bankTransferId: number, planPriceId: string): Promise<void> {
    const res = await PostRequest<TransferInfoResponse>(
      `/payment/bank_transfer/${bankTransferId}/${planPriceId}`,
      "",
      true, // 共通エラー処理のダイアログを表示しない
      true, // エラー時にthrowする
    );
    if (!res || !res.bankTransferId) {
      throw new Error(`PaymentRepository.bankTransfer API Error: ${JSON.stringify(res)}`);
    }
  }

  async prepareSettlement(
    id: number,
    planPriceId: string,
    divideTimes: number,
    cancelPlanPriceId?: string,
  ): Promise<number> {
    const body: Record<string, any> = {};
    if (divideTimes > 1) {
      body.divide_times = divideTimes;
    }
    if (cancelPlanPriceId) {
      body.cancel_plan_price_id = cancelPlanPriceId;
    }
    const res = await PostRequest<{ statusCode: number; result: { creditResultId: number } }>(
      `/payment/credit-prepare-settlement/${id}/${planPriceId}`,
      JSON.stringify(body),
      true, // 共通エラー処理のダイアログを表示しない
      false, // トークンリフレッシュではない
      true, // エラー時にthrowする
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`PaymentRepository.prepareSettlement API Error: ${JSON.stringify(res)}`);
    }
    return res.result.creditResultId;
  }

  async settlement(
    creditResultId: number,
    isUpdateContract?: boolean,
    cancelPlanPriceId?: string,
  ): Promise<void> {
    const body: Record<string, any> = {};
    if (isUpdateContract) {
      body.is_update_contract = isUpdateContract;
    }
    if (cancelPlanPriceId) {
      body.cancel_plan_price_id = cancelPlanPriceId;
    }
    const res = await PostRequest<{ statusCode: number }>(
      `/payment/credit-settlement/${creditResultId}`,
      JSON.stringify(body),
      true, // 共通エラー処理のダイアログを表示しない
      false, // トークンリフレッシュではない
      true, // エラー時にthrowする
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`PaymentRepository.settlement API Error: ${JSON.stringify(res)}`);
    }
  }

  async changeCreditCard(id: number, planId: string): Promise<void> {
    const res = await PutRequest<{ statusCode: number }>(
      `/payment/credit-card-change/${id}/${planId}`,
      "",
      true, // 共通エラー処理のダイアログを表示しない
      true, // エラー時にthrowする
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`PaymentRepository.changeCreditCard API Error: ${JSON.stringify(res)}`);
    }
  }

  private toPayment(creditCard: CreditCard): Payment {
    // セキュリティコードはAPIから取得できないので空白とする。カード番号もマスク済みのものをそのまま設定
    // https://invinc.slack.com/archives/C04HG0404HJ/p1697070757988299
    const code = "";

    return new Payment(
      creditCard.sbPaymentId,
      creditCard.cardBrandCode,
      creditCard.ccNumber,
      creditCard.ccExpiration.substr(4, 2),
      creditCard.ccExpiration.substr(0, 4),
      code,
      creditCard.isUsedCard,
      !creditCard.isAvailableCard,
    );
  }

  private toExpiration(payment: Payment): string {
    return `${payment.expireYear}${payment.expireMonth}`;
  }

  // SBペイメントサービスのトークン生成ロジック
  private generateToken(payment: Payment): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const afterGenerateToken = function (response: { result: string; tokenResponse: any }) {
        if (response.result === "OK") {
          resolve(response.tokenResponse);
        } else {
          reject(new Error("generateToken failed"));
        }
      };
      // トークン生成ロジック呼び出し
      // eslint-disable-next-line camelcase
      com_sbps_system.generateToken(
        {
          merchantId: "92818",
          serviceId: "001",
          ccNumber: payment.cardNumber,
          ccExpiration: this.toExpiration(payment),
          securityCode: payment.code,
        },
        afterGenerateToken,
      );
    });
  }
}
