import type { ConversionResponse } from "~/entities/ConversionResponse";
import type { IConversionRepository } from "~/interfaces/IConversionRepository";

export class ConversionRepository implements IConversionRepository {
  /**
   * サンクスページに表示するプランを取得する
   */
  async getConversion(planId: number): Promise<ConversionResponse> {
    const res = await GetRequest<ConversionResponse>(`/conversion/${planId}`);
    return res;
  }

  /**
   * ユーザが講座毎にCVした履歴を登録する
   */
  async postConversion(planId: number): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(`/conversion/${planId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed to post browse");
    }
  }
}
